import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import CodeList from "../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

let codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

export default function CardLogisPartnerModal({ partnerDetail }) {
  let bMapTime = partnerDetail.bMapTime;
  let bMapKey = partnerDetail.bMapKey;
  const bMapObj = useRef();

  let [mapView, setMapView] = useState(false);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
      "CardLogisPartnerModal",
      (b, n) => {
        //console.log("EVENT_HOMEPAGE_DRAW_MODAL_MAP", n);
        bMapKey = n.bMapKey;
        bMapTime = n.bMapTime;
        bMapObj.current.innerHTML = "";
        if (bMapTime === "") return;
        setTimeout(() => {
          bMapObj.current.id = `daumRoughmapContainer${bMapTime}`;
          new window.daum.roughmap.Lander({
            timestamp: bMapTime,
            key: bMapKey,
            mapWidth: "436",
            mapHeight: "360",
          }).render();
        }, 500);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
        "CardLogisPartnerModal"
      );
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="partnerDetailModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {partnerDetail.bBranchName}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row pb-3">
                <div className="col">
                  <div
                    className="image"
                    style={{
                      width: "100%",
                      height: "125px",
                      background: `url(//cardlogis.com/uploadFiles/logo/${partnerDetail.bLogo}) 50% 50% / contain no-repeat`,
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-2">
                  <p className="font-weight-bold">소개글</p>
                  <p
                    className="w-100"
                    style={{
                      whiteSpace: "break-spaces",
                      wordBreak: "break-all",
                    }}
                  >
                    {partnerDetail.bMemo}
                  </p>
                </div>
                {partnerDetail.bHomepage && (
                  <div className="col-12 py-2">
                    <p className="font-weight-bold">홈페이지</p>
                    <a
                      className="text-primary text-decoration-underline"
                      href={
                        partnerDetail.bHomepage.startsWith("http")
                          ? partnerDetail.bHomepage
                          : `http://${partnerDetail.bHomepage}`
                      }
                      target="_blank"
                    >
                      {console.log(partnerDetail.bHomepage.startsWith("http"))}
                      {partnerDetail.bHomepage.startsWith("http")
                        ? partnerDetail.bHomepage
                        : `http://${partnerDetail.bHomepage}`}
                    </a>
                  </div>
                )}

                <div className="col-6 py-2">
                  <p className="font-weight-bold">설립일</p>
                  <p>{partnerDetail.bBranchDate}</p>
                </div>

                <div className="col-6 py-2">
                  <p className="font-weight-bold">대표자</p>
                  <p>{partnerDetail.bCEOName}</p>
                </div>
                <div className="col-6 py-2">
                  <p className="font-weight-bold">담당자</p>
                  <p>{partnerDetail.bUserName}</p>
                </div>
                <div className="col-6 py-2">
                  <p className="font-weight-bold">전화번호</p>
                  <p>{partnerDetail.bTel}</p>
                </div>

                <div className="col-12 py-2">
                  <p className="font-weight-bold">e-mail</p>
                  <p>{partnerDetail.bEmail}</p>
                </div>
                <div className="col-12 py-2">
                  <p className="font-weight-bold">주소지</p>
                  <p>{partnerDetail.bAddrRoadAddress}</p>
                </div>
                <div
                  className="col-12 bg-light
                root_daum_roughmap root_daum_roughmap_landing
                "
                  id="test"
                  ref={bMapObj}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
