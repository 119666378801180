import React, { useState, useEffect } from "react";
import TermsNav from "./TermsNav";
import MainFooter from "../main/MainFooter";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import "./Terms.css";

export default function Terms() {
  let [pages, setPages] = useState([
    {
      id: "privacyPolicy",
      component: <PrivacyPolicy />,
      menu: "개인정보처리방침",
    },
    {
      id: "termsOfService",
      component: <TermsOfService />,
      menu: "서비스이용약관",
    },
  ]);

  return (
    <div id="main" className="body-wrapper d-flex flex-column h-100">
      <TermsNav pages={pages} />
      <div id="terms">
        {pages.map((page) => {
          if (window.location.hash === `#${page.id}`) {
            return <div style={{ marginTop: "64px" }} key={page.id}>{page.component}</div>;
          }
        })}
      </div>
      <MainFooter />
    </div>
  );
}
