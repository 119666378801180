import React, { useEffect, useState, useRef } from "react";
import CommonHeader from "../CommonHeader";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import { Link } from "react-router-dom";
import PaymentRequestTable from "./PaymentRequestTable";
import axios from "axios";
import CodeList from "../../lib/CodeList";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};
const PAY_STATUS = [
  { text: "대기중", value: "R" },
  { text: "결제완료", value: "P" },
  { text: "일부결제", value: "P1" },
  { text: "일부취소", value: "C1" },
  { text: "전체취소", value: "C" },
];

function PaymentRequestList(props) {
  const [userInfo, setUserInfo] = useState({ uLevel: "0", uID: null });

  let tempDate = new Date();
  tempDate.setDate(new Date().getDate() - new Date().getDay());
  let [searchStartDate, setSearchStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );
  let [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );
  let [paymentStatus, setPaymentStatus] = useState({
    ready_cnt: 0,
    ready_cost: 0,
    paid_cnt: 0,
    paid_cost: 0,
    cancel_cnt: 0,
    cancel_cost: 0,
  });
  let dateBtnList = [
    { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
    { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
    { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
    { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
    { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
  ];

  let [searchHistoryStartDate, setSearchHistoryStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );
  let [searchHistoryEndDate, setSearchHistoryEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  const $controller = {
    searchText: useRef(),
    searchState: useRef(),
    searchStartDate: useRef(),
    searchEndDate: useRef(),
    searchHistoryStartDate: useRef(),
    searchHistoryEndDate: useRef(),
  };

  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
    $controller.searchStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    $controller.searchEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );
    filterChanged();
  };

  const changeHistoryDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchHistoryStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchHistoryEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
    $controller.searchHistoryStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    $controller.searchHistoryEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );
    getStatisticsData();
  };

  useEffect(() => {
    getStatisticsData();
    filterChanged();
  }, [userInfo]);

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "PaymentRequestList",
      (b, n) => {
        //console.log("HeaderAccController EVENT_LOGIN_AUTO_SUCCESS", n);
        if (n.ret) {
          setUserInfo({ ...n["returnData"][0] });
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "PaymentRequestList"
      );
    };
  }, []);

  const getStatisticsData = () => {
    //JS_payment_statistics_select.php

    if (userInfo.uID === null) return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/payment/JS_payment_statistics_select.php",
      msgID: msgID,
      data: {},
      JoinStartDate: $controller.searchHistoryStartDate.current.value,
      JoinEndDate: $controller.searchHistoryEndDate.current.value,
      uID: userInfo.uLevel == "0" ? userInfo.uID : "",
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setPaymentStatus(newData["returnData"][0]);
        }
      }
    });
  };

  const filterChanged = () => {
    //console.log(userInfo);
    console.log('filterChanged')
    if (userInfo.uID === null) return;
    let filter = {
      searchText: $controller.searchText.current.value,
      searchStartDate: $controller.searchStartDate.current.value,
      searchEndDate: $controller.searchEndDate.current.value,
      searchState: $controller.searchState.current.value,
      uID: userInfo.uLevel == "0" ? userInfo.uID:""  ,
    };
    socket.sendLocalMessage(
      MsgIDList.EVENT_PAYMENT_LIST_FILTER_CHANGED,
      "PaymentRequestList",
      filter,
      () => {}
    );
  };


  const downloadExcel = (e) => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//cardlogis.com/backside/payment/JS_payment_excel_export.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              searchText: $controller.searchText.current.value,
              searchStartDate: $controller.searchStartDate.current.value,
              searchEndDate: $controller.searchEndDate.current.value,
              searchState: $controller.searchState.current.value,
              uID: userInfo.uLevel == "0" ? userInfo.uID : "",
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `결제요청내역_${util.todayDate("YYYYMMDD")}.xls`
            );
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    );
  };

  const makeNewPayment = (e) => {
    $("#btnNavPaymentRequest").click();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">결제 요청서 관리</h1>
            </div>

            <div className="row mb-2">
              <div className="col-12  col-md-6 col-xl-6 order-2 order-md-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-12 col-md-3 mb-2 mb-md-0">
                        <div className="text text-info text-center btn w-100">
                          <strong>간편조회</strong>
                        </div>
                      </div>

                      <div className="btn-group col-12 col-md-9 " role="group">
                        {dateBtnList.map((btn) => {
                          return (
                            <button
                              className="btn  btn-outline-info"
                              data-range={btn.value}
                              onClick={changeHistoryDatePicker}
                            >
                              {btn.desc}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3  mb-md-0">
                        <div className="text text-info text-center btn  w-100">
                          <strong>조회기간</strong>
                        </div>
                      </div>
                      <div className=" col-12 col-md-9">
                        <div className="row">
                          <div className="col">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="date"
                                value={searchHistoryStartDate}
                                ref={$controller.searchHistoryStartDate}
                                onChange={(e) => {
                                  setSearchHistoryStartDate(e.target.value);
                                  if (e.target.value > searchHistoryEndDate) {
                                    setSearchHistoryEndDate(e.target.value);
                                  }
                                  getStatisticsData();
                                }}
                              />
                              <div className="input-group-append">
                                <label className="input-group-text">-</label>
                              </div>

                              <input
                                className="form-control"
                                type="date"
                                value={searchHistoryEndDate}
                                ref={$controller.searchHistoryEndDate}
                                onChange={(e) => {
                                  setSearchHistoryEndDate(e.target.value);
                                  if (e.target.value < searchHistoryStartDate) {
                                    setSearchHistoryStartDate(e.target.value);
                                  }
                                  getStatisticsData();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  결제 현황 정보*/}
              <div className="col-12 col-md-6 col-xl-6 order-1 order-md-1">
                <div className="row d-flex">
                  <div className="col-12 col-md-4 col-xl d-flex">
                    <div className="card flex-fill">
                      <div className="card-body py-3">
                        <div className="float-end text-success">
                          {util.addCommas(paymentStatus.ready_cnt)}건
                        </div>
                        <p className="mb-2 h4">결제 대기</p>
                        <div className="mb-1 text-end">
                          <strong>
                            {util.addCommas(paymentStatus.ready_cost)} 원
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl d-flex">
                    <div className="card flex-fill">
                      <div className="card-body py-3">
                        <div className="float-end text-success">
                          {util.addCommas(paymentStatus.paid_cnt)}건
                        </div>
                        <p className="mb-2 h4">결제 완료</p>
                        <div className="mb-1 text-end">
                          <strong>
                            {util.addCommas(paymentStatus.paid_cost)} 원
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl d-flex">
                    <div className="card flex-fill">
                      <div className="card-body py-3">
                        <div className="float-end text-success">
                          {util.addCommas(paymentStatus.cancel_cnt)}건
                        </div>
                        <p className="mb-2 h4">결제 취소</p>
                        <div className="mb-1 text-end">
                          <strong>
                            {util.addCommas(paymentStatus.cancel_cost)} 원
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    {/*타이틀 영역*/}
                    <div className="card-title">
                      <div className=" row">
                        <h4 className="col-auto">결제 요청 내역</h4>
                        <div className="col text-right">
                          <a onClick={makeNewPayment}>
                            <div className="btn btn-info mr-2">
                              <i className="fas fa-plus" /> 새로만들기
                            </div>
                          </a>
                          <button
                            className="btn btn-info"
                            onClick={downloadExcel}
                          >
                            <i className="fas fa-download" /> 엑셀파일로 받기
                          </button>
                        </div>
                      </div>
                    </div>
                    <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                      <div className="row justify-content-end">
                        <div className="col-auto mb-2">
                          <div className="btn-group  mr-2 " role="group">
                            {dateBtnList.map((btn) => {
                              return (
                                <button
                                  className="btn  btn-outline-info"
                                  data-range={btn.value}
                                  onClick={changeDatePicker}
                                >
                                  {btn.desc}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-auto mb-2">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="date"
                              value={searchStartDate}
                              ref={$controller.searchStartDate}
                              onChange={(e) => {
                                setSearchStartDate(e.target.value);
                                if (e.target.value > searchEndDate) {
                                  setSearchEndDate(e.target.value);
                                }
                                filterChanged();
                              }}
                            />
                            <span className="input-group-append">
                              <div className="input-group-text">-</div>
                            </span>
                            <input
                              className="form-control"
                              type="date"
                              value={searchEndDate}
                              ref={$controller.searchEndDate}
                              onChange={(e) => {
                                setSearchEndDate(e.target.value);
                                if (e.target.value < searchStartDate) {
                                  setSearchStartDate(e.target.value);
                                }
                                filterChanged();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-auto mb-2">
                          <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                            <select
                              className="custom-select form-control"
                              ref={$controller.searchState}
                              style={{ minWidth: 150 }}
                              onChange={filterChanged}
                            >
                              <option value="">모든결제상태</option>
                              {PAY_STATUS.map((e) => {
                                return (
                                  <option value={e.value}>{e.text}</option>
                                );
                              })}
                            </select>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="검색어"
                              aria-label="검색어"
                              aria-describedby="btnGroupAddon2"
                              ref={$controller.searchText}
                              onKeyPress={(e) => {
                                console.log(e);
                                if (e.charCode === 13) {
                                  filterChanged(e);
                                }
                              }}
                            />

                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                onClick={filterChanged}
                              >
                                <i className="fas fa-search mr-2" />
                                검색
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <PaymentRequestTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentRequestList;
