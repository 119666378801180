import React, { useState } from "react";

function CardLogisFooter() {
  return (
    <section className="py-0 py-xxl-6" id="help">
      <div
        className="bg-holder"
        style={{ background: `url(./images/footer-bg.png)` }}
      />
      <div className="container">
        <div className="row min-vh-75 min-vh-xl-50 pt-10">
          <div className="col-6 col-md-4 col-xl-3 mb-3">
            <h5 className="lh-lg fw-bold text-white">관계 협력사</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  OOO물류
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  OOO관세사
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  OOO서비스
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  OOO서비스
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-xl-3 mb-3">
            <h5 className="lh-lg fw-bold text-white">FEATURES</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Beginner Guide
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Move to Servion
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Website Builder
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Tools and Resources
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-xl-3 mb-3">
            <h5 className="lh-lg fw-bold text-white">SUPPORT</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Help Center
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Submit a Ticket
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Contact Us
                </a>
              </li>
              <li className="lh-lg">
                <a className="text-200 text-decoration-none" href="#!">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-3 mb-3">
            <h5 className="lh-lg fw-bold text-white">WE ACCEPT</h5>
            <ul className="list-unstyled list-inline mb-6 mb-md-0">
              <li className="list-inline-item mr-2">
                <a className="text-decoration-none" href="#!">
                  <img
                    className="list-social-icon"
                    src="./images/visa.png"
                    width="50"
                    alt="..."
                  />
                </a>
              </li>
              <li className="list-inline-item mr-2">
                <a className="text-decoration-none" href="#!">
                  <img
                    className="list-social-icon"
                    src="./images/master-card.png"
                    width="50"
                    alt="..."
                  />
                </a>
              </li>
              <li className="list-inline-item mr-2">
                <a className="text-decoration-none" href="#!">
                  <img
                    className="list-social-icon"
                    src="./images/union-pay.png"
                    width="50"
                    alt="..."
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-decoration-none" href="#!">
                  <img
                    className="list-social-icon"
                    src="./images/express.png"
                    width="50"
                    alt="..."
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row flex-center pb-3">
          <div className="col-md-6 order-0">
            <p className="text-200 text-center text-md-start">
              All rights Reserved &copy; CARD LOGIS, 2021
            </p>
          </div>
          <div className="col-md-6 order-1">
            <p className="text-200 text-center text-md-end">
              <a
                className="text-200 fw-bold"
                href="https://themewagon.com/"
                target="_blank"
              >
                카드로지스
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardLogisFooter;
