import React, { useState } from "react";
import MainNav from "./MainNav";
import MainCarousel from "./MainCarousel";
import IntroduceCard from "./IntroduceCard";
import CardLogisPartner from "./CardLogisPartner";
import CardLogisPartnerInquiry from "./CardLogisPartnerInquiry";
import MainFooter from "./MainFooter";
import FaQMain from "../faqCenter/FaQMain";
import "./mainlib/style.css";

export default function Main() {
  let [pages, setPages] = useState([
    {
      id: "mainCarousel",
      component: <MainCarousel />,
      menu: "서비스소개",
    },
     { id: "introduceCard", component: <IntroduceCard />, menu: "제휴카드소개" },
    {
      id: "cardLogisPartner",
      component: <CardLogisPartner />,
      menu: "가맹점현황",
    },
    {
      id: "cardLogisPartnerInquiry",
      component: <CardLogisPartnerInquiry />,
      menu: "가입문의",
    },
  ]);

  return (
    <>
      <div id="main" className="body-wrapper d-flex flex-column h-100">
        <MainNav pages={pages} />
        {window.location.hash !== "#faq" && window.location.pathname === "/" ? (
          pages.map((page) => {
            return  <section key={page.id}>{page.component}</section>;
          })
        ) : (
          <FaQMain />
        )}
        <MainFooter />
      </div>
    </>
  );
}
