import React, { useState, useEffect } from "react";
import "./mainlib/aos.css";

export default function MainCarousel() {
  return (
    <section id="mainCarousel" className="section gradient-banner">
      <div className="shapes-container">
        <div
          className="shape"
          data-aos="fade-down-left"
          data-aos-duration="1500"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="fade-up-right"
          data-aos-duration="1000"
          data-aos-delay="200"
        ></div>
        <div
          className="shape"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="300"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="200"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="zoom-out"
          data-aos-duration="2000"
          data-aos-delay="500"
        ></div>
        <div
          className="shape"
          data-aos="fade-up-right"
          data-aos-duration="500"
          data-aos-delay="200"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-left"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
        <div
          className="shape"
          data-aos="fade-down"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
        <div
          className="shape"
          data-aos="fade-up-right"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          className="shape"
          data-aos="fade-down-left"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
      </div>
      <div className="container">
        <div className="row align-items-center mt-5">
          <div className="col-md-6 order-2 order-md-1 text-center text-md-left">
            <h2 className="text-white font-weight-bold mb-4">
              기업의 수출입물류비 이제는 현금대신 카드 결제 받으세요!
            </h2>
            <p className="text-white mb-5 h4">
              카드로지스는 포워더등 물류기업이 화주기업으로부터 현금대신
              신용카드를 통하여 물류비를 결제 받을수 있도록, 신용카드 전자결제
              가맹점 가입부터 결제관리 솔루션까지 물류비 카드결제를 위한 토탈
              서비스를 제공합니다.
              <br />
              <br />
              특히, 온라인상점이 없는 물류기업들의 PG가입이 가능하도록 회원사
              전용의 결제환경을 제공하며, 카드로지스의 물류비 전용 제휴카드로
              결제하는 경우 고객혜택(결제액의 0.5%리워드)을 극대화하여
              고객만족이 더욱 높아 집니다.
              <br />
              <br />
              고객의 결제부담을 낮추어 영업경쟁력을 확보하고, 현금미수의
              부담에서 벗어날 수 있는 물류비의 신용카드 결제 이제는 선택이 아닌
              필수입니다.
              <br />
              <br />
              카드로지스 가입대상 업종 : 포워더, 운송사, 관세사, 물류창고
              <br />
              <br />
              카드로지스 가입 및 이용금액 : 무료(FREE)
            </p>
            <a
              href="#cardLogisPartnerInquiry"
              className="btn btn-info btn-lg text-white"
            >
              가입 문의
            </a>
          </div>
          <div className="col-md-6 text-center order-1 order-md-2">
            <img
              className="img-fluid"
              src="images/cardlogis_card.png"
              alt="screenshot"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
