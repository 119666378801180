import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardLogisLoginModal from "./CardLogisLoginModal";

function CardLogisNav() {
  let [isLogin, setIsLogin] = useState(false);

  console.log(isLogin);

  return (
    <>
      <div
        className="navbar navbar-expand-lg navbar-light fixed-top d-block"
        data-navbar-on-scroll="data-navbar-on-scroll"
        style={{
          backgroundImage: "none",
          backgroundColor: "rgba(66, 77, 131, 1)",
          transition: "none 0s ease 0s",
        }}
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col col-lg-auto">
              <p className="navbar-brand text-white font-weight-bold m-0">
                카드로지스
              </p>
            </div>
            <div className="col col-lg-0 align-items-end">
              <button
                className="navbar-toggler float-end border-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="text-white fas fa-bars" />
              </button>
            </div>
            <div className="col-0 col-lg-auto">
              <div className="row">
                <div className="col pt-lg-4">
                  <div
                    className="collapse navbar-collapse border-top border-lg-0 mb-4 mt-lg-0"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto pt-2 pt-lg-0 font-base">
                      <li className="nav-item px-2" data-anchor="data-anchor">
                        <a
                          className="nav-link fw-medium active"
                          aria-current="page"
                          href="/#carousel"
                        >
                          카드소개
                        </a>
                      </li>
                      <li className="nav-item px-2" data-anchor="data-anchor">
                        <a className="nav-link" href="/#franchisee">
                          가맹점현황
                        </a>
                      </li>
                      <li className="nav-item px-2" data-anchor="data-anchor">
                        <a className="nav-link" href="/#membershipInquiry">
                          가입문의
                        </a>
                      </li>
                      <li className="nav-item px-2" data-anchor="data-anchor">
                        <Link to="/HelpMain" className="nav-link">
                          도움말
                        </Link>
                      </li>
                    </ul>
                    {isLogin ? (
                      <>
                        <div className="dropdown">
                          <button
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            My Page
                            <svg
                              className="bi bi-person-fill"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#ffffff"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/ERP">
                                <i className="align-middle  mr-1 fas fa-cog" />
                                ERP 바로가기
                              </Link>
                            </li>
                            <li>
                              <Link to="/HelpMain" className="dropdown-item">
                                <i className="align-middle mr-1 far fa-question-circle" />
                                도움말
                              </Link>
                            </li>
                            <li>
                              <div className="dropdown-divider" />
                            </li>
                            <li>
                              <span
                                className="dropdown-item"
                                onClick={() => setIsLogin(false)}
                              >
                                Log out
                              </span>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                      >
                        Login
                        <svg
                          className="bi bi-person-fill"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isLogin && (
        <CardLogisLoginModal isLogin={isLogin} setIsLogin={setIsLogin} />
      )}
    </>
  );
}

export default CardLogisNav;
