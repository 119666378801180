import React, { useEffect, useState } from "react";
import CommonSlideNavbar from "./CommonSlideNavbar";
import PaymentRequestList from "./paymentRequestList/PaymentRequestList";
import PaymentRequestRegister from "./paymentRequestRegist/PaymentRequestRegister";
import UserManagement from "./userManagement/UserManagement";
import BranchManage from "./branchManage/BranchManage";
import FaqManage from "./faqManage/FaqManage";
import LimitInquiry from "./limitInquiry/LimitInquiry";
import "./css/ruang-admin.css";
import CommonHeader from "./CommonHeader";
import AutoLogin from "../lib/AutoLogin";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function PaymentPlatform({ history, match }) {
  const [userInfo, setUserInfo] = useState({ uLevel: 0 });
  let [pagesOpen, setPagesOpen] = useState({
    ManagePaymentRequests: true,
    PaymentRequest: false,
    LimitInquiry: false,
    UserManagement: false,
    BranchManage: false,
    FaqManage: false,
  });
  let [pages, setPages] = useState([
    { name: "type", title: "결제요청", uLevel: 0 },
    {
      name: "ManagePaymentRequests",
      title: "결제 요청서 관리",
      page: <PaymentRequestList />,
      uLevel: 0,
    },
    {
      name: "PaymentRequest",
      title: "결제 요청서 등록",
      page: <PaymentRequestRegister />,
      uLevel: 0,
    },
    {
      name: "LimitInquiry",
      title: "제휴카드 한도조회",
      page: <LimitInquiry />,
      uLevel: 0,
    },
    { name: "type", title: "관리자메뉴", uLevel: 1 },
    {
      name: "UserManagement",
      title: "가맹점 계정관리",
      page: <UserManagement />,
      uLevel: 1,
    },
    {
      name: "BranchManage",
      title: "가맹점 소개관리",
      page: <BranchManage />,
      uLevel: 1,
    },
    { name: "FaqManage", title: "FAQ 관리", page: <FaqManage />, uLevel: 1 },
  ]);

  useEffect(() => {
    console.log(history, match);
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "PaymentPlatform",
      (beforeData, newData) => {
        if (newData.ret) {
          setUserInfo({ ...newData["returnData"][0] });
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      "PaymentPlatform",
      (beforeData, newData) => {
        console.log("PaymentPlatform EVENT_LOGIN_AUTO_FAIL");
        codeList.Modal.current.alert("로그인 후 이용해주세요", () => {
          history.replace("/");
        });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "PaymentPlatform"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "PaymentPlatform"
      );
    };
  }, []);

  let PageChangeHandler = (name) => {
    let pageList = { ...pagesOpen };
    for (let key in pageList) {
      pageList[key] = key === name;
    }
    setPagesOpen(pageList);
  };

  return (
    <div id="wrapper" className="wrapper">
      <AutoLogin />
      <CommonSlideNavbar
        PageChangeHandler={PageChangeHandler}
        pages={pages}
        pagesOpen={pagesOpen}
      />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <CommonHeader />
          {pages.map((p) => {
            return (
              <div
                key={p.name}
                style={
                  !pagesOpen[p.name] ? { display: "none" } : { display: "" }
                }
                className="main"
              >
                {p.page}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PaymentPlatform;
