import React, { useEffect, useState, useRef } from "react";
import Utilities from "../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonHeader from "../CommonHeader";
import UserManagementTable from "./UserManagementTable";
import { Link } from "react-router-dom";
import DatePicker from "react-dater";
import "react-dater/dist/index.css";
import { ko } from "date-fns/locale";
import axios from "axios";
import CodeList from "../../lib/CodeList";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};

function UserManagement(props) {
  let [searchStartDate, setSearchStartDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );
  let [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  let dateBtnList = [
    { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
    { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
    { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
    { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
    { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
  ];

  useEffect(() => {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    startDate.setDate(startDate.getDate() - theDayOfWeek);

    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
  }, []);

  let $searchText = useRef();
  let $searchState = useRef();
  let $searchLevel = useRef();
  let $searchJoinStartDate = useRef();
  let $searchJoinEndDate = useRef();
  let $searchJoinBool = useRef();

  const addNewData = (e) => {
    console.log(e);
    socket.sendLocalMessage(
      MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      "UserManagement",
      {},
      () => {}
    );

    //params.openEdit(e, params)
  };
  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));

    $searchJoinBool.current.checked = true;
  };

  const searchFilterChanged = (e) => {
    console.log($searchJoinStartDate.current, searchStartDate, searchEndDate);
    console.log(
      $searchText.current.value,
      $searchState.current.value,
      $searchLevel.current.value,
      $searchJoinStartDate.current.value,
      $searchJoinEndDate.current.value,
      $searchJoinBool.current.checked
    );

    const filters = {
      WORK_TYPE: $searchState.current.value,
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
      JoinStartDate: $searchJoinBool.current.checked
        ? $searchJoinStartDate.current.value
        : "",
      JoinEndDate: $searchJoinBool.current.checked
        ? $searchJoinEndDate.current.value
        : "",
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_USER_MANAGE_FILTER_CHANGED,
      "UserManagement",
      filters,
      () => {}
    );

    console.log("searchFilterChanged");
  };

  const downloadExcel = (e) => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//cardlogis.com/backside/account/JS_account_excel_export.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              WORK_TYPE: $searchState.current.value,
              SEARCH_WORD: $searchText.current.value,
              SEARCH_LEVEL: $searchLevel.current.value,
              JoinStartDate: $searchJoinBool.current.checked
                ? $searchJoinStartDate.current.value
                : "",
              JoinEndDate: $searchJoinBool.current.checked
                ? $searchJoinEndDate.current.value
                : "",
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `사용자계정리스트_${util.todayDate("YYYYMMDD")}.xls`
            );
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">가맹점 계정 관리</h1>
            </div>
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    {/*필터 영역*/}
                    <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                      {/*간편기간 영역*/}

                      {/*필터*/}
                      <div
                        className="btn-toolbar mb-3 justify-content-end"
                        role="toolbar"
                      >
                        <div className="btn-group  mr-2 " role="group">
                          {dateBtnList.map((btn) => {
                            return (
                              <button
                                className="btn  btn-outline-info"
                                data-range={btn.value}
                                onClick={changeDatePicker}
                              >
                                {btn.desc}
                              </button>
                            );
                          })}
                        </div>

                        <div className="input-group ">
                          <div className="input-group-prepend">
                            <label className="input-group-text">
                              <input
                                className="mt-0 mr-2"
                                type="checkbox"
                                aria-label="Checkbox for following text input"
                                ref={$searchJoinBool}
                              />
                              가입일
                            </label>
                          </div>

                          <div className="input-group date">
                            <input
                              className="form-control"
                              type="date"
                              value={searchStartDate}
                              ref={$searchJoinStartDate}
                              onChange={(e) => {
                                console.log(e);
                                setSearchStartDate(e.target.value);
                                if (e.target.value > searchEndDate) {
                                  setSearchEndDate(e.target.value);
                                }
                                //searchFilterChanged(e);
                              }}
                            />
                          </div>

                          {/*<input
                            className="form-control"
                            type="date"
                            value={searchStartDate}
                            ref={$searchJoinStartDate}
                            onChange={(e) => {
                              console.log(e);
                              setSearchStartDate(e.target.value);
                              if (e.target.value > searchEndDate) {
                                setSearchEndDate(e.target.value);
                              }
                              //searchFilterChanged(e);
                            }}
                          />*/}
                          <div className="input-group-prepend">
                            <label className="input-group-text">-</label>
                          </div>

                          <input
                            className="form-control"
                            type="date"
                            value={searchEndDate}
                            ref={$searchJoinEndDate}
                            onChange={(e) => {
                              setSearchEndDate(e.target.value);
                              if (e.target.value < searchStartDate) {
                                setSearchStartDate(e.target.value);
                              }
                              //searchFilterChanged(e);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <div
                          className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                          role="group"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            data-toggle="modal"
                            data-target="#staticUserManageEdit"
                            onClick={addNewData}
                            alt={"신규등록"}
                            aria-label={"신규등록"}
                          >
                            <i className="fas fa-plus" /> 신규등록
                          </button>

                          <button
                            className="btn  btn-outline-primary"
                            onClick={downloadExcel}
                          >
                            <i className="fas fa-download mr-2" />
                            엑셀파일로 받기
                          </button>
                        </div>

                        <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                          <select
                            className="custom-select form-control"
                            ref={$searchLevel}
                            onChange={searchFilterChanged}
                            style={{ minWidth: 150 }}
                          >
                            <option value="">권한선택</option>
                            <option value="1">관리자</option>
                            <option value="0">가맹점</option>
                          </select>

                          <select
                            className="custom-select form-control"
                            ref={$searchState}
                            onChange={searchFilterChanged}
                            style={{ minWidth: 150 }}
                          >
                            <option value="">사용상태</option>
                            <option value="Y">사용가능</option>
                            <option value="N">중지</option>
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어"
                            aria-label="검색어"
                            aria-describedby="btnGroupAddon2"
                            onKeyPress={(e) => {
                              console.log(e);
                              if (e.charCode === 13) {
                                searchFilterChanged(e);
                              }
                            }}
                            ref={(ref) => ($searchText.current = ref)}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              onClick={searchFilterChanged}
                            >
                              <i className="fas fa-search mr-2" />
                              검색
                            </button>
                          </div>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <UserManagementTable />
                  {/*<Table
                      columns={columns}
                      data={data}
                      id="gridAccount"
                      ref={$grid}
                    />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagement;
