import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./App.css";
import BuddibleSocket from "./lib/BuddibleSocket";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./lib/ScrollToTop";
import PaymentPlatform from "./paymentPlatform/PaymentPlatform";
import UserPayment from "./paymentPlatform/userPayment/UserPayment";
import HelpMain from "./homePage/help/HelpMain";
import Main from "./main/Main";
import CardInfo from "./cardinfo/CardInfo";
import Terms from "./terms/Terms";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const socket = new BuddibleSocket();

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/CardInfo" component={CardInfo} />
        <Route exact path="/PaymentPlatform" component={PaymentPlatform} />
        <Route exact path="/ERP" component={PaymentPlatform} />
        <Route exact path="/UserPayment" component={UserPayment} />
        <Route exact path="/HelpMain" component={HelpMain} />
        <Route exact path="/Terms" component={Terms} />
        <Route exact path="/" component={Main} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
