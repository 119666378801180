import React from "react";
import PropTypes, { string } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import TableFooter from "@mui/material/TableFooter/TableFooter";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();

const TAX_TYPE = [
  { text: "과세", value: "A" },
  { text: "영세", value: "B" },
  { text: "면세", value: "C" },
];

const WORK_YN = [
  { text: "사용", value: "Y" },
  { text: "중지", value: "N" },
];

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const createOrderNumber = () => {
  let today = new Date();
  return `Danal_${util.todayDate("YYYYMMDD")}${
    today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
  }${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}${
    today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()
  }_sub_${util.makeUUIDv4Short()}`;
};

//테이블 헤드 데이터 테이블
const headCells = [
  {
    field: "psTaxType",
    headerName: "과세구분",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => {
      return util.getCodeName(params.data, TAX_TYPE);
    },
    renderCell: (params) => (
      <span>
        <select
          className="custom-select form-control"
          style={{ minWidth: "4.5rem" }}
          onChange={(e) => {
            params.currentData.psTaxType = e.target.value;
            params.changedDataByUser();
          }}
        >
          <option value="A" selected={params.currentData.psTaxType === "A"}>
            과세
          </option>
          <option value="B" selected={params.currentData.psTaxType === "B"}>
            영세
          </option>
          <option value="C" selected={params.currentData.psTaxType === "C"}>
            면세
          </option>
        </select>
      </span>
    ),
  },

  {
    field: "pmProductName",
    headerName: "상품(서비스)명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <input
          type="text"
          className="form-control"
          placeholder="요청항목의 적요"
          onChange={(e) => {
            console.log(params.currentData);
            params.currentData.pmProductName = e.target.value;
            params.changedDataByUser();
          }}
        />
      </span>
    ),
  },
  {
    field: "psBaseCost",
    headerName: "공급가액",
    type: "string",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <input
          type="text"
          className="form-control text-end"
          placeholder="공급가액"
          onChange={(e) => {
            params.currentData.psBaseCost = e.target.value;
            if (
              params.currentData.psTaxType === "A" &&
              parseInt(params.currentData.psBaseCost, 10)
            ) {
              params.currentData.psTax = util.addCommas(
                parseInt(parseInt(params.currentData.psBaseCost, 10) * 0.1, 10)
              );
              e.target.value = util.addCommas(params.currentData.psBaseCost);
              params.changedDataByUser();
            } else {
              e.target.value = util.addCommas(params.currentData.psBaseCost);
              params.currentData.psTax = 0;
              params.changedDataByUser();
            }
          }}
          onKeyUp={(e) => {
            params.currentData.psBaseCost = e.target.value.replace(
              /[^0-9]/gi,
              ""
            );
            if (
              params.currentData.psTaxType === "A" &&
              parseInt(params.currentData.psBaseCost, 10)
            ) {
              params.currentData.psTax = util.addCommas(
                parseInt(parseInt(params.currentData.psBaseCost, 10) * 0.1, 10)
              );
              e.target.value = util.addCommas(params.currentData.psBaseCost);
              params.changedDataByUser();
            } else {
              e.target.value = util.addCommas(params.currentData.psBaseCost);
              params.currentData.psTax = 0;
              params.changedDataByUser();
            }
          }}
        />
      </span>
    ),
  },

  {
    field: "psTax",
    headerName: "세액",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <input
          type="text"
          className="form-control text-end"
          placeholder="세액"
          value={params.currentData.psTax}
          disabled={true}
        />
      </span>
    ),
  },

  {
    field: "pmMemo",
    headerName: "비고",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <input
          type="text"
          className="form-control"
          placeholder="비고"
          onChange={(e) => {
            params.currentData.psMemo = e.target.value;
            params.changedDataByUser();
          }}
        />
      </span>
    ),
  },
  {
    field: "psOrderURL",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    //
    renderCell: (params) => (
      <span>
        {params.currentData.psIDX === 0 && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-primary"
            onClick={(e) => params.addPayment(e, params)}
            aria-label={"추가"}
          >
            <i className="fas fa-plus " />
          </button>
        )}
        {params.currentData.psIDX > 0 && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger"
            onClick={(e) => params.removePayment(params.currentData.psIDX)}
            aria-label={"삭제"}
          >
            <i className="fas fa-minus " />
          </button>
        )}
      </span>
    ),
  },
];

function Row(props) {
  const {
    row,
    index,
    addPayment,
    removePayment,
    changedDataByUser,
    isMultiSelect = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      /*onClick={(event) => handleClick(event, row.id)}*/
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.field}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
        >
          {headCell.hasOwnProperty("renderCell")
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                addPayment: addPayment,
                removePayment: removePayment,
                changedDataByUser: changedDataByUser,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                addPayment: addPayment,
                removePayment: removePayment,
                changedDataByUser: changedDataByUser,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

//테이블 헤드 생성 함수
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    console.log("createSortHandler");
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.headerName}
            {/*<TableSortLabel
            active={orderBy === headCell.field}
            direction={orderBy === headCell.field ? order : "asc"}
            onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//테이블 헤드 생성 함수 프로토 타입
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

//테이블 전체 생성 함수
export default function PaymentRequestRegisterTable({
  isServerPaging = false,
  isServerSort = false,
  ShowPreViewParent,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  let index = 0;
  const $controller = {
    total_base_cost: useRef(),
    total_tax_cost: useRef(),
    total_amount: useRef(),
  };

  //요청 금액 데이터
  let newOrderNum = createOrderNumber();
  const [rowData, setRowData] = useState([
    {
      psIDX: 0,
      psTaxType: "A",
      pmProductName: "",
      psBaseCost: "",
      psTax: "",
      psMemo: "",
      psOrderURL: "",
      psOderID: "",
      psOderIDSub: newOrderNum,
      psTotalCost: "",
      psPayStatus: "",
      psCancelURL: "",
      psReceiptURL: "",
    },
  ]);

  //요청 금액 데이터
  const [footerData, setFooterData] = useState([
    {
      psBaseCost_total: "",
      psTax_total: "",
      total: "",
    },
  ]);

  const [sortModel, setSortModel] = useState([
    {
      field: "uID",
      sort: "asc",
    },
  ]);
  let filters = {};

  useEffect(() => {
    //console.log(rowData);
    //changedDataByUser();
  }, [rowData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL,
      "PaymentRequestRegisterTable",
      (b, n) => {
        for (let key in $controller) {
          if ($controller.hasOwnProperty(key)) {
            if ($controller[key].current) $controller[key].current.value = "";
          }
        }
        let newOrderNum = createOrderNumber();
        console.log(n, newOrderNum);
        setRowData([
          {
            psIDX: 0,
            psTaxType: "A",
            pmProductName: "",
            psBaseCost: "",
            psTax: "",
            psMemo: "",
            psOrderURL: "",
            psOderID: n.pmOderID,
            psOderIDSub: newOrderNum,
            psTotalCost: "",
            psPayStatus: "",
            psCancelURL: "",
            psReceiptURL: "",
          },
        ]);
        $("#tblPaymentRequest input").val("");
        $("#tblPaymentRequest select").val("A");
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL,
        "PaymentRequestRegisterTable"
      );
    };
  }, []);

  const data = React.useMemo(() => {
    //console.log("useMemo");
    return [...rowData];
  }, [rowData]);

  const handleChangePage = (event, newPage) => {
    console.log(event, newPage);
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalCount.length - page * rowsPerPage);*/
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  // + 버튼 이벤트
  function openEdit(e, params) {
    console.log(e, params);
    //console.log("openEdit", e.target.dataset, params);
    // editModal.current.openModal(e, params);
  }

  const addPayment = () => {
    let paymentList = [...rowData];
    let nextType = "A";
    let tax_types = [...TAX_TYPE];
    if (rowData.length > 2) return;
    for (let i = 0; i < rowData.length; i++) {
      for (let j = tax_types.length - 1; j > -1; j--) {
        console.log(rowData[i]["psTaxType"], tax_types[j]["value"]);
        if (rowData[i]["psTaxType"] === tax_types[j]["value"]) {
          tax_types.splice(j, 1);
        }
      }
    }
    console.log(tax_types);
    nextType = tax_types[0]["value"];
    console.log(nextType);

    paymentList.push({
      psIDX: ++index,
      psTaxType: nextType,
      pmProductName: "",
      psBaseCost: "",
      psTax: "",
      pmMemo: "",
      psOrderURL: "",
      psOderID: rowData[0].psOderID,
      psOderIDSub: createOrderNumber(),
      psTotalCost: "",
      psPayStatus: "",
      psCancelURL: "",
      psReceiptURL: "",
      psMemo: "",
    });
    setRowData([...paymentList]);
  };

  const removePayment = (idx) => {
    let paymentList = [...rowData];
    let position = 0;
    for (let i = 0; i < paymentList.length; i++) {
      if (paymentList[i]["psIDX"] === idx) {
        position = i;
      }
    }
    paymentList.splice(position, 1);
    setRowData([...paymentList]);
  };

  const changedDataByUser = (newData) => {
    let copyData = [...rowData];

    let total_base_cost = 0;
    let total_tax_cost = 0;
    let total_amount = 0;
    let pmProductName = "";
    for (let i = 0; i < copyData.length; i++) {
      if (i === 0) pmProductName = copyData[i].pmProductName;
      if (copyData[i].psBaseCost.hasOwnProperty("replace"))
        copyData[i].psBaseCost.replace(/[^0-9]/gi, "");
      if (copyData[i].psBaseCost !== "")
        total_base_cost += parseInt(copyData[i].psBaseCost, 10);

      copyData[i].psTax.toString().replace(/[^0-9]/gi, "");
      /*copyData[i].psTax = ("" + copyData[i].psTax).replace(/[^0-9]/gi, "");*/

      let tax = copyData[i].psTax.toString().replace(/[^0-9]/gi, "");

      if (copyData[i].psTax !== "") total_tax_cost += parseInt(tax, 10);

      copyData[i].psTotalCost = util.addCommas(
        parseInt(copyData[i].psBaseCost, 10) + parseInt(tax, 10)
      );
      /*console.log(
        total_tax_cost,
        typeof copyData[i].psTax,
        copyData[i].psTax,
        copyData[i].psTax.toString().replace(/[^0-9]/gi, "")
      );*/
    }
    total_amount = total_base_cost + total_tax_cost;

    $controller.total_base_cost.current.value = util.addCommas(total_base_cost);
    $controller.total_tax_cost.current.value = util.addCommas(total_tax_cost);
    $controller.total_amount.current.value = util.addCommas(total_amount);

    setRowData([...rowData]);
    socket.sendLocalMessage(
      MsgIDList.EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED,
      "PaymentRequestRegisterTable",
      {
        subData: [...rowData],
        pmBaseCost: total_base_cost,
        pmTax: total_tax_cost,
        pmTotalCost: total_amount,
        pmProductName: pmProductName,
      }
    );
  };

  const ShowPreView = (e) => {
    ShowPreViewParent([...rowData]);
    console.log(rowData);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table" id="tblPaymentRequest">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {/*row 데이터를 이용해 테이블이 생성되는 곳*/}
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  addPayment={addPayment}
                  removePayment={removePayment}
                  changedDataByUser={changedDataByUser}
                />
              ))}

          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  addPayment={addPayment}
                  removePayment={removePayment}
                  changedDataByUser={changedDataByUser}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  addPayment={addPayment}
                  removePayment={removePayment}
                  changedDataByUser={changedDataByUser}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                addPayment={addPayment}
                removePayment={removePayment}
                changedDataByUser={changedDataByUser}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow className={[classes.root, "text-truncate"].join(" ")}>
            <TableCell colSpan={2} align={"right"}>
              결제금액 합계
            </TableCell>

            <TableCell>
              <input
                type="text"
                className="form-control text-end"
                placeholder="공급가 합계"
                disabled={true}
                ref={$controller.total_base_cost}
              />
            </TableCell>
            <TableCell>
              <input
                type="text"
                className="form-control text-end"
                placeholder="세금 합계"
                disabled={true}
                ref={$controller.total_tax_cost}
              />
            </TableCell>
            <TableCell colSpan={2}>
              <input
                type="text"
                className="form-control text-end"
                placeholder="총금액"
                disabled={true}
                ref={$controller.total_amount}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align={"right"}>
              <button
                className="btn btn-primary"
                onClick={(e) => ShowPreView(e)}
              >
                미리보기 적용
              </button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

//테이블 전체 생성 함수 프로토타입
PaymentRequestRegisterTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
