import React, { useState } from "react";
import { Link } from "react-router-dom";
import GBTSLogoSvg from "../assets/GBTSLogoSvg";

export default function MainFooter() {
  let [footerMenuList, setFooterMenuList] = useState([
    {
      mainMenu: "PRODUCT",
      subMenu: [
        { menuName: "Teams", link: "/Main" },
        { menuName: "Blogs", link: "/Main" },
        { menuName: "FAQ", link: "/Main" },
      ],
    },
    {
      mainMenu: "RESOURCES",
      subMenu: [
        { menuName: "Signup", link: "/Main" },
        { menuName: "Login", link: "/Main" },
        { menuName: "Blog", link: "/Main" },
      ],
    },
    {
      mainMenu: "COMPANY",
      subMenu: [
        { menuName: "Career", link: "/Main" },
        { menuName: "Contact", link: "/Main" },
        { menuName: "Investor", link: "/Main" },
        { menuName: "Terms", link: "/Main" },
      ],
    },
    {
      mainMenu: "COMPANY",
      subMenu: [
        { menuName: "About", link: "/Main" },
        { menuName: "Contacts", link: "/Main" },
        { menuName: "Team", link: "/Main" },
        { menuName: "Privacy Policy", link: "/Main" },
      ],
    },
  ]);
  return (
    <>
      <footer className="footer mt-auto pt-3">
        <div className=" footer-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <a href="/#mainCarousel">
                  <GBTSLogoSvg color={"#cecece"} />
                </a>
              </div>
              <div className="col-lg-9 col-md-12 m-md-auto align-self-center">
                <p>
                  상호: 주식회사 지비티에스 대표이사:서창길
                  <br />
                  주소 : 인천광역시 중구 흰바위로59번길8, 220호(운서동)
                  <br />
                  사업자등록번호 : 693-81-01474 | 통신판매업신고:
                  제2020-인천중구-0070호
                  <br />
                  고객센터 : 032-751-9880 | E-MAIL : GBTS@BSTC.KR
                </p>
                <p className="mt-2">
                  <Link
                    className="text-primary"
                    to={{
                      pathname: "/Terms",
                      hash: "#privacyPolicy",
                    }}
                    target="_blank"
                  >
                    개인정보 처리방침 및 이용동의약관
                  </Link>
                  <span> | </span>
                  <Link
                    className="text-primary"
                    to={{
                      pathname: "/Terms",
                      hash: "#termsOfService",
                    }}
                    target="_blank"
                  >
                    서비스이용약관
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center bg-dark py-4">
          <small className="text-secondary">
            All rights Reserved &copy; CARD LOGIS 2021
          </small>
        </div>
      </footer>
    </>
  );
}
