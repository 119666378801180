import React, { useState, useEffect } from "react";
import LoginModal from "./LoginModal";

import { Link } from "react-router-dom";

export default function MainNav({ pages }) {
  let [isLogin, setIsLogin] = useState(false);
  let [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    if (sessionStorage.length) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  });

  useEffect(() => {
    try {
      window.addEventListener("scroll", ActiveMainMenu);
      return () => {
        window.removeEventListener("scroll", ActiveMainMenu);
      };
    } catch (e) {}
  });

  const ActiveMainMenu = () => {
    if (window.location.hash === "#faq" || !window.location.hash) {
      setActiveMenu("");
      return;
    }
    let sy = window.scrollY;
    pages.forEach((page, index) => {
      let py = document.getElementById(`${page.id}`).offsetTop;
      if (py - 150 <= sy && py + 150 >= sy) {
        setActiveMenu(page.id);
      }
    });
  };

  return (
    <>
      <nav className="navbar main-nav navbar-expand-lg position-fixed w-100 px-2 px-sm-0 py-2 py-lg-0 shadow-sm">
        <div className="container">
          <p className="navbar-brand nav-item text-primary font-weight-bold">
            <a href="#mainCarousel" className="nav-link dropdown col-auto py-3">
              <img src="./images/cardlogis logo.png" alt="mainlogo" />
            </a>
          </p>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu">
              <i className="fas fa-bars" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mr-auto mr-lg-0 ml-0 ml-lg-auto align-items-end align-items-lg-center">
              {pages.map((page) => {
                if (page.menu !== "") {
                  return (
                    <li
                        key={page.id}
                      className={`nav-item dropdown ${
                        `#${page.id}` === `#${activeMenu}` ? "active" : ""
                      }`}
                    >
                      <a className="nav-link" href={`/#${page.id}`}>
                        {page.menu}
                      </a>
                    </li>
                  );
                }
              })}
              <li
                className={`nav-item dropdown ${
                  window.location.hash === "#faq" ? "active" : ""
                }`}
              >
                <a className="nav-link" href="#faq">
                  도움말
                </a>
              </li>
              {isLogin ? (
                <li className="nav-item">
                  <div
                    className="nav-link dropdown"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="myPageMenuButton"
                    >
                      My Page
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="myPageMenuButton"
                    >
                      <Link
                        className="dropdown-item text-end text-lg-center"
                        to="/ERP"
                      >
                        <i className="fas fa-cog" /> ERP 바로가기
                      </Link>
                      <Link
                        to="#faq"
                        className="dropdown-item text-end text-lg-center"
                      >
                        <i className="far fa-question-circle" /> 도움말
                      </Link>
                      <div className="dropdown-divider" />
                      <span
                        className="dropdown-item text-end text-lg-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          sessionStorage.clear();
                          setIsLogin(false);
                        }}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="nav-item mr-3 mr-lg-0 ml-lg-3">
                  <span
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#loginModal"
                  >
                    Login
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <LoginModal setIsLogin={setIsLogin} />
    </>
  );
}
