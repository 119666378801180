import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

export default function TermsNav({ pages }) {
  let [activeMenu, setActiveMenu] = useState("");

  return (
    <>
      <nav className="navbar main-nav navbar-expand-lg position-fixed w-100 px-2 px-sm-0 py-2 py-lg-0 shadow-sm">
        <div className="container">
          <p className="navbar-brand nav-item text-primary font-weight-bold">
            <Link
              to="/#mainCarousel"
              className="nav-link dropdown col-auto py-3"
            >
              <img src="./images/cardlogis logo.png" alt="mainlogo" />
            </Link>
          </p>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu">
              <i className="fas fa-bars" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mr-auto mr-lg-0 ml-0 ml-lg-auto align-items-end align-items-lg-center">
              {pages.map((page) => {
                if (page.menu !== "") {
                  return (
                    <li
                        key={page.id}
                      className={`nav-item dropdown ${
                        `#${page.id}` === `#${activeMenu}` ? "active" : ""
                      }`}
                    >
                      <a
                        className="nav-link"
                        href={`/Terms#${page.id}`}
                        onClick={() => {
                          setActiveMenu(page.id);
                        }}
                      >
                        {page.menu}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
