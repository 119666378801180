import React, { useRef, useState } from "react";
import CommonHeader from "../CommonHeader";
import Utilities from "../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import $ from "jquery";
import CodeList from "../../lib/CodeList";
const socket = new BuddibleSocket();
let util = new Utilities();
const codeList = new CodeList();

function LimitInquiry() {
  let [loading, setLoading] = useState(false);

  const $controllers = {
    domainSelector: useRef(),
    inputDomain: useRef(),
    inputEmail: useRef(),
    inputCoRegNo: useRef(),
    inputCoName: useRef(),
  };

  const mailDomainChanged = () => {
    $controllers.inputDomain.current.value =
      $controllers.domainSelector.current.value;
  };

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        name: $controllers.inputCoName.current.value.trim(),
        email: `${$controllers.inputEmail.current.value.trim()}@${$controllers.inputDomain.current.value.trim()}`,
        coRegNo: $controllers.inputCoRegNo.current.value.trim(),
      };

      requestDataUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controllers.inputCoName.current.value.trim() === "") {
      codeList.Modal.current.alert("거래처 상호명을 입력해 주세요.", () => {});
      return false;
    }

    if ($controllers.inputCoRegNo.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "사업자 등록번호를 입력해 주세요.",
        () => {}
      );
      return false;
    }

    if (
      $controllers.inputEmail.current.value.trim() === "" ||
      $controllers.inputDomain.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이메일주소를 입력해 주세요.", () => {});
      return false;
    }

    return true;
  };

  const requestDataUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/contact_me.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);

      if (newData) {
        console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            MsgIDList.EVENT_PAYMENT_REGISTER_DATA_CHANGED,
            "PaymentRequestRegister",
            newData,
            () => {}
          );
          codeList.Modal.current.alert("정보 저장이 완료되었습니다.", () => {});
          clearAllData();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "한도조회 요청 중 오류가 발생하였습니다.",
            () => {}
          );
        }
      } else {
        setLoading(false);
        codeList.Modal.current.alert(
          "한도조회 요청 중 오류가 발생하였습니다.",
          () => {}
        );
      }
    });
  };

  const clearAllData = () => {
    for (let key in $controllers) {
      if ($controllers.hasOwnProperty(key)) {
        if ($controllers[key].current) $controllers[key].current.value = "";
      }
    }
  };

  return (
    <div className="main">
      <div className="content">
        <div className="container-fluid">
          {/*  헤드라인*/}
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h3>
                <strong>고객사 제휴카드 한도조회 요청</strong>
              </h3>
              <p>
                카드로지스의 제휴카드인 삼성 물류비전용 법인카드의 가입을
                희망하는 고객사의 발급가능여부 및 예상한도를 조회하여 회신
                드립니다. 제휴카드 결제를 통하여 고객사 적립포인트도 제안하시고
                PG수수료도 절감하시기 바랍니다.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-7 col-xxl-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col ">
                      <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">
                          상호명
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            ref={$controllers.inputCoName}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">
                          사업자등록번호
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            ref={$controllers.inputCoRegNo}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-3">
                          <label className="col-form-label">
                            조회결과 회신 받을 이메일
                          </label>
                        </div>

                        <div className="col-sm-9">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              ref={$controllers.inputEmail}
                            />

                            <div className="input-group-prepend">
                              <label className="input-group-text">@</label>
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              ref={$controllers.inputDomain}
                            />

                            <select
                              className="custom-select form-control"
                              onChange={mailDomainChanged}
                              ref={$controllers.domainSelector}
                            >
                              <option value="">직접입력</option>
                              <option value="naver.com">naver.com</option>
                              <option value="daum.net">daum.net</option>
                              <option value="hanmail.net">hanmail.net</option>
                              <option value="gmail.com">gmail.com</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="btn-toolbar justify-content-end">
                        <div className="input-group">
                          <button
                            className="btn btn-primary "
                            onClick={() => {
                              codeList.Modal.current.confirm(
                                "한도조회를 요청 하시겠습니까?",
                                (ret) => {
                                  if (ret) actionSave();
                                }
                              );
                            }}
                            disabled={loading}
                          >
                            조회 요청
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*               <div className="col">
                      상호명, 사업자등록번호,e-mail을 적은 후 조회 요청 시 해당
                      이메일을 통해 고객사의 카드 한도 정보를 회신 드립니다.
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LimitInquiry;
