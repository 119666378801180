import React, { useState } from "react";
import CardLogisNav from "../CardLogisNav";
import CardLogisFooter from "../CardLogisFooter";
import "./HelpMain.css";

function HelpMain() {
  let [faqList, setFaqList] = useState([
    {
      name: "서비스 문의",
      key: "service",
      faq: [
        {
          frequently: "카드로지스는 어떤 서비스인가요?",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
    {
      name: "가맹점 문의",
      key: "franchisee",
      faq: [
        {
          frequently: "가맹점 문의 글 입니다.",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
    {
      name: "기타 문의",
      key: "etc",
      faq: [
        {
          frequently: "기타 문의 글 입니다.",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
  ]);

  return (
    <div className="main" id="helpMain">
      <CardLogisNav />
      <div className="pt-8 mt-8">
        <div className="container bg-light">
          <div className="row bg-secondary p-5 p-xl-10">
            <div className="col-12 text-center h2 font-weight-bold text-white mb-5">
              무엇을 도와드릴까요?
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-0 col-lg-3" />
                <div className="col-12 col-lg-6">
                  <div className="row align-items-center">
                    <div className="col-10 p-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색"
                      />
                    </div>
                    <div className="col-2 text-center">
                      <i className="fas fa-search text-white display-4" />
                    </div>
                  </div>
                </div>
                <div className="col-0 col-lg-3" />
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-10">
            <div className="col">
              <div className="tab">
                <ul
                  id="customTabHeader"
                  className="nav nav-tab nav-tabs"
                  role="tablist"
                >
                  {faqList.map((menu, index) => {
                    console.log(menu.key);
                    return (
                      <li className="nav-item" id="customTabMenu">
                        <a
                          className={`nav-link ${index === 0 ? "active" : ""}`}
                          href={`#${menu.key}`}
                          data-bs-toggle="tab"
                          role="tab"
                          //----------------------------
                          id={`${menu.key}_tab`}
                          data-bs-target={`#${menu.key}_tab_content`}
                          type="button"
                          aria-controls={`${menu.key}_tab_content`}
                          aria-selected={index === 0}
                        >
                          {menu.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="tab-content mt-3" id="nav-tabContent">
                  {faqList.map((faq, index) => {
                    return (
                      <div
                        className={`tab-pane fade ${
                          index === 0 ? "show active" : ""
                        }`}
                        id={`${faq.key}_tab_content`}
                        role="tabpanel"
                        aria-labelledby={`${faq.key}_tab`}
                      >
                        {/*--------------------------------*/}
                        <div className="accordion accordion-flush" id="helpFaq">
                          {faq.faq.map((data, index) => {
                            return (
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${index}`}
                                  >
                                    {data.frequently}
                                  </button>
                                </h2>

                                <div
                                  id={`collapse${index}`}
                                  className="accordion-collapse collapse" // show collapse
                                  aria-labelledby={`heading${index}`}
                                  data-bs-parent="#helpFaq"
                                >
                                  <div className="accordion-body">
                                    {data.questions}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/*  .............................*/}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardLogisFooter />
    </div>
  );
}

export default HelpMain;
