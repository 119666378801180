import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import $ from "jquery";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let paymentList = [
  {
    total_cost: 12252100,
    base_cost: 11026890,
    tax_cost: 1225210,
    tax_immunity: 0,
    receipt_id: "5416d21-1d59d87-d122d0",
    order_uid: "s84984d21-d7478f8_a4s522",
    escrow_yn: "N",
    u_name: "김김김",
    u_email: "_",
    u_addr: "_",
    u_tel: "000-0000-0000",
    card_name: "_",
    card_num: "0000-0000-0000-0000",
    installment_period: "0",
    card_approval_num: "sd5s4df-4fg1263559-a118",
    company: "에이스",
    product: "해상운임 외 2건",
    paymentLink: "http://eedd...",
    request: "Y",
    request_date: "2021-08-30",
    success: "Y",
    success_date: "2021-08-30",
  },
  {
    total_cost: 12252100,
    base_cost: 11026890,
    tax_cost: 1225210,
    tax_immunity: 0,
    receipt_id: "5416d21-1d59d87-d12d30",
    order_uid: "s84984d21-d7478f8_a4s5sds22",
    escrow_yn: "N",
    u_name: "김ㅇㅇ",
    u_email: "_",
    u_addr: "_",
    u_tel: "000-0000-0000",
    card_name: "_",
    card_num: "0000-0000-0000-0000",
    installment_period: "0",
    card_approval_num: "sd5s4df-4fg1263559-a118",
    company: "베스트",
    product: "해상운임 외 2건",
    paymentLink: "http://eedd...",
    request: "Y",
    request_date: "2021-08-30",
    success: "N",
    success_date: "2021-08-30",
  },
  {
    total_cost: 12252100,
    base_cost: 11026890,
    tax_cost: 1225210,
    tax_immunity: 0,
    receipt_id: "5416d21-1d59d87-d12d0",
    order_uid: "s84984d21-d7478dfdf8_a4s522",
    escrow_yn: "N",
    u_name: "유ㅇㅇ",
    u_email: "_",
    u_addr: "_",
    u_tel: "000-0000-0000",
    card_name: "_",
    card_num: "0000-0000-0000-0000",
    installment_period: "0",
    card_approval_num: "sd5s4df-4fg1263559-a118",
    company: "베스트",
    product: "해상운임 외 2건",
    paymentLink: "http://eedd...",
    request: "Y",
    request_date: "2021-08-30",
    success: "N",
    success_date: "2021-08-30",
  },
];

const AGENT_LEVEL = [
  { text: "가맹점", value: "0" },
  { text: "관리자", value: "1" },
  { text: "개발자", value: "3" },
];

const WORK_YN = [
  { text: "사용", value: "Y" },
  { text: "중지", value: "N" },
];

const PAY_STATUS = [
  { text: "대기중", value: "A" },
  { text: "결제완료", value: "B" },
  { text: "일부결제", value: "C" },
  { text: "일부취소", value: "D" },
];

const TAX_TYPE = [
  { text: "과세", value: "A" },
  { text: "영세", value: "B" },
  { text: "면세", value: "C" },
];

const PAY_SUB_STATUS = [
  { text: "대기중", value: "R" },
  { text: "결제완료", value: "P" },
  { text: "취소완료", value: "C" },
];

const useRowStyles = makeStyles({
  root: {},
});

const headCells = [
  {
    field: "pmProductName",
    headerName: "상품명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "psTaxType",
    headerName: "과세구분",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => {
      return util.getCodeName(params.data, TAX_TYPE);
    },
  },
  {
    field: "psBaseCost",
    headerName: "공급가액",
    type: "string",
    minWidth: 150,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },

  {
    field: "psTax",
    headerName: "세액",
    type: "string",
    minWidth: 110,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },
  {
    field: "psPayStatus",
    headerName: "결제상태",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => util.getCodeName(params.data, PAY_SUB_STATUS),

    renderCell: (params) => (
      <span
        className={
          params.data === "P"
            ? "text-primary"
            : params.data === "C"
            ? "text-danger"
            : ""
        }
      >
        {util.getCodeName(params.data, PAY_SUB_STATUS)}
      </span>
    ),
  },
  {
    field: "psOrderURL",
    headerName: "결제 URL",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => (
      <span>
        <div className="btn-toolbar justify-content-center" role="toolbar">
          <div className="input-group order-1 order-md-1 order-lg-1  bm-sm-0">
            <input
              id={params.data}
              className="form-control"
              value={params.data}
              style={{ width: "110px" }}
            />
            <button
              className="btn btn-sm btn-primary input-group-text "
              onClick={(e) => {
                let copyText = document.getElementById(params.data);
                copyText.select();
                document.execCommand("Copy");
              }}
              disabled={params.cancelBillStatus}
              alt={"복사"}
              aria-label={"복사"}
            >
              <i className="fas fa-copy " />
            </button>
          </div>
        </div>
      </span>
    ),
  },
  {
    field: "psCancelURL",
    headerName: "승인취소",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {params.currentData.psPayStatus === "P" && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger btn-sm ml-2"
            onClick={(e) => params.cancelOrder(e, params)}
          >
            <i className="fas fa-credit-card " />
            {params.cancelBillStatus && (
              <span
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        )}
      </span>
    ),
  },
  {
    field: "psCancelURL",
    headerName: "삭제",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {params.currentData.psPayStatus === "R" && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger btn-sm ml-2"
            onClick={(e) => params.deleteOrder(e, params)}
            disabled={params.cancelBillStatus}
          >
            <i className="fas fa-times " />
            {params.cancelBillStatus && (
              <span
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        )}
      </span>
    ),
  },
];

const PaymentDetailView = ({
  payment,
  ReceiptModalHandler,
  rowData,
  parentData,
}) => {
  return (
    <tr className="container" id={payment.order_uid}>
      <td colSpan="8" className="bg-light">
        {/*결제정보*/}
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">결제정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">총결제금액</h6>{" "}
                <p>{util.addCommas(rowData.psTotalCost)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">공급가액</h6>{" "}
                <p>{util.addCommas(rowData.psBaseCost)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">부가세</h6>{" "}
                <p>{util.addCommas(rowData.psTax)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">면세금액</h6>{" "}
                <p>{util.addCommas(payment.tax_immunity)} 원</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">결제번호 PG TID</h6>{" "}
                <p>{rowData.dc_TID}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">주문 번호</h6>{" "}
                <p>{rowData.psOderIDSub}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">구매영수증</h6>{" "}
                <a
                  className="btn btn-outline-secondary btn-sm"
                  href={`//cardlogis.com/backside/danal_receipt.php?cpid=${rowData.dc_CPID}&TID=${rowData.dc_TID}&AMOUNT=${rowData.dc_AMOUNT}&cpkey=${rowData.dc_CPKEY}`}
                  target="popup"
                  onClick={(e) => {
                    window.open(
                      `//cardlogis.com/backside/danal_receipt.php?cpid=${rowData.dc_CPID}&TID=${rowData.dc_TID}&AMOUNT=${rowData.dc_AMOUNT}&cpkey=${rowData.dc_CPKEY}`,
                      "popup",
                      "width=600,height=600"
                    );
                  }}
                >
                  영수증보기
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*사용자정보*/}
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">사용자정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">사용자명</h6>{" "}
                <p>{parentData.pmCompanyName}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">이메일</h6>{" "}
                <p>{parentData.pmCustomerName}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">주소</h6>{" "}
                <p>{parentData.u_addr}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">전화번호</h6>{" "}
                <p>{parentData.u_tel}</p>
              </div>
            </div>
          </div>
        </div>
        {/*카드정보*/}
        <div className="card">
          <div className="card-header">
            <div className="card-title font-weight-bold">카드 결제정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드사명</h6>{" "}
                <p>{rowData.CARDNAME}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">할부기간</h6>{" "}
                <p>{rowData.dc_QUOTA}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드번호</h6>{" "}
                <p>{rowData.dc_CARDNO}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드승인번호</h6>{" "}
                <p>{rowData.dc_CARDAUTHNO}</p>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

function Row(props) {
  const {
    parentData,
    cancelOrder,
    row,
    index,
    openEdit,
    cancelBillStatus,
    isMultiSelect = false,
    deleteOrder,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        size={"small"}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  cancelOrder: cancelOrder,
                  cancelBillStatus: cancelBillStatus,
                  deleteOrder: deleteOrder,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  cancelOrder: cancelOrder,
                  cancelBillStatus: cancelBillStatus,
                  deleteOrder: deleteOrder,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <PaymentDetailView
                    payment={paymentList[0]}
                    rowData={row}
                    parentData={parentData}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

/*        <TableCell component="th" scope="row">
          {row.field}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>*/

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    console.log("createSortHandler");
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

export default function PaymentRequestTableSub({
  isServerPaging = true,
  isServerSort = true,
  rowData,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelBillStatus, setCancelBillStatus] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("psOderIDSub");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "psOderIDSub",
      sort: "asc",
    },
  ]);
  //console.log(rowData);
  let filters = {
    psOderID: rowData.pmOderID,
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_FILTER_CHANGED,
      "PaymentRequestTableSub",
      (b, n) => {
        //console.log(n);
        filters = n;
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_SUB_NEED_REFRESH,
      "PaymentRequestTableSub",
      (b, n) => {
        //console.log(n);
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_FILTER_CHANGED,
        "PaymentRequestTableSub"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_SUB_NEED_REFRESH,
        "PaymentRequestTableSub"
      );
    };
  }, []);

  useEffect(() => {
    //console.log("useEffect [page, sortModel]", page, sortModel);
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/payment/JS_payment_sub_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          //let returnData = newData["ReturnData"];
          //newData.data = newData["ReturnData"];
          //newData["ReturnData"] = [];
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
          //console.log(ServerData);
        }
      }
    });
  };

  const data = React.useMemo(() => {
    //console.log("useMemo");
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    console.log(event, newPage);
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalCount.length - page * rowsPerPage);*/
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    //console.log("openEdit", e.target.dataset, params);
    editModal.current.openModal(e, params);
  }

  /*  const cancelOrder = (e, params) => {
    console.log(params);
    codeList.Modal.current.confirm("결제승인을 취소하시겠습니까?", (ret) => {
      if (ret === true) {
        window.open(
          `//cardlogis.com/backside/danal_BillCancel.php?CPID=${params.currentData.dc_CPID}&TID=${params.currentData.dc_TID}&AMOUNT=${params.currentData.dc_AMOUNT}`,
          "popup",
          "width=600,height=600"
        );
      }
    });
  };*/

  const cancelOrder = (e, params) => {
    //setLoading(true);
    codeList.Modal.current.confirm("결제승인을 취소하시겠습니까?", (ret) => {
      if (ret === true) {
        setCancelBillStatus(true);
        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/danal_BillCancel.php",
          msgID: msgID,
          data: {},
          CPID: params.currentData.dc_CPID,
          CRYPTOKEY: params.currentData.dc_CPKEY,
          TID: params.currentData.dc_TID,
          AMOUNT: params.currentData.dc_AMOUNT,
          psOderIDSub: params.currentData.psOderIDSub,
        };
        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
          console.log(newData);
          setCancelBillStatus(false);
          if (newData) {
            console.log(newData["ret"]);
            if (newData["ret"] === true) {
              setLoading(false);
              socket.sendLocalMessage(
                MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
                "PaymentRequestTableSub",
                newData,
                () => {}
              );
              codeList.Modal.current.alert(
                `결제취소가 정상적으로 진행되었습니다.\n ${newData.RETURNMSG}`,
                () => {}
              );

              params.currentData.psPayStatus = "C";
              requestUserSelect();
            } else {
              setLoading(false);
              codeList.Modal.current.alert(
                `결제취소중 오류가 발생하였습니다. 관리자에게 문의 해주세요.\n ${newData.RETURNMSG}`,
                () => {}
              );
            }
          }
        });
      }
    });
  };

  const deleteOrder = (e, params) => {
    //setLoading(true);
    codeList.Modal.current.confirm("결제 요청서를 삭제하시겠습니까?", (ret) => {
      if (ret === true) {
        setCancelBillStatus(true);
        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/payment/JS_payment_request_sub_update.php",
          msgID: msgID,
          data: {},
          CPID: params.currentData.dc_CPID,
          TID: params.currentData.dc_TID,
          AMOUNT: params.currentData.dc_AMOUNT,
          psOderIDSub: params.currentData.psOderIDSub,
          psOderID: params.currentData.psOderID,
        };
        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
          console.log(newData);
          setCancelBillStatus(false);
          if (newData) {
            console.log(newData["ret"]);
            if (newData["ret"] === true) {
              setLoading(false);
              socket.sendLocalMessage(
                MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
                "PaymentRequestTableSub",
                newData,
                () => {}
              );
              codeList.Modal.current.alert(
                `결제 요청서가 정상적으로 삭제되었습니다.`,
                () => {}
              );

              params.currentData.psPayStatus = "C";
              requestUserSelect();
            } else {
              setLoading(false);
              codeList.Modal.current.alert(
                `결제요청서 삭제 중 오류가 발생하였습니다. 관리자에게 문의 해주세요.`,
                () => {}
              );
            }
          }
        });
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size={dense ? "small" : "medium"}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  cancelOrder={cancelOrder}
                  parentData={rowData}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  cancelOrder={cancelOrder}
                  parentData={rowData}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  cancelOrder={cancelOrder}
                  parentData={rowData}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                openEdit={openEdit}
                cancelOrder={cancelOrder}
                parentData={rowData}
                cancelBillStatus={cancelBillStatus}
                deleteOrder={deleteOrder}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/*   <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            console.log(value - 1); //apiRef.current.setPage(value - 1)
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>*/}
    </TableContainer>
  );
}

PaymentRequestTableSub.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
