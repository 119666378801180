import React, { useState, useEffect } from "react";

export default function IntroduceCard() {
  let [introduce, setIntroduce] = useState([
    {
      title: "캐시백 0.5%",
      desc:
        "전월 이용금액 관계없이,\n" +
        "캐시백한도 없이 국내외\n" +
        "이용금액의 0.5% 캐시백",
      iconClass: "fas fa-hand-holding-usd",
    },
    {
      title: "연회비 무료",
      desc:
        "[국내] 연회비 없음\n" +
        "[MASTER] 연회비 없음\n" +
        "[VISA] 연회비 없음",
      iconClass: "fab fa-creative-commons-zero",
    },
  ]);


  return (
      <>
        <section id="introduceCard" className="features py-5">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>제휴카드소개</h2>
              <p>CARDLOGIS의 제휴 카드</p>
            </div>
            <div className="row">
              <div
                  className="image col-lg-6"
                  style={{
                    background: `url("/images/cardlogis_card.png") 50% 50% /contain no-repeat`,
                    minHeight: '250px'
                  }}
                  data-aos="fade-right"

              />
              <div
                  className="col-lg-6 d-flex align-items-center "
                  data-aos="fade-left"
                  data-aos-delay="100"
              >
                <div>
                  <div
                      className=" mt-5 mt-lg-0"
                      data-aos="zoom-in"
                      data-aos-delay="150"
                      style={{width:'100%'}}
                  >

                    <h4>카드로지스 제휴카드 입점 준비중입니다!</h4>
                    <p>
                      카드로지스 제휴카드를 통해 더욱 많은 혜택이 제공될 수 있도록
                      노력하고 있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );

  return (
    <>
      <section className="service section" id="introduceCard">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2 className="font-weight-bold">제휴 카드 소개</h2>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6 align-self-center">
              <div
                className="service-thumb mb-5 mb-lg-0 align-items-center text-center"
                data-aos="fade-right"
              >
                <img
                  className="img-fluid"
                  id="introCardImg"
                  src="images/samsung_card(dark).png"
                  alt="samsung_card"
                />
              </div>
            </div>
            <div className="col-lg-auto mx-auto ml-xl-0 mr-xl-auto align-self-center">
              <h1
                className="font-weight-bold text-center"
                style={{ letterSpacing: "-2px", lineHeight: "1.2em" }}
              >
                수출입 물류비, 통관비
                <br />
                편리하게 납부하고
                <br />
                한도 없이 0.5% 캐시백!
              </h1>
              <div className="mt-4 d-flex justify-content-center">
                <a
                  className="mr-2 btn btn-lg px-5 btn-primary text-white rounded-pill"
                  href="/cardinfo"
                  target="_blank"
                >
                  자세히 보기
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
