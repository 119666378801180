import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PaymentRequestTableSub from "./PaymentRequestTableSub";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const AGENT_LEVEL = [
  { text: "가맹점", value: "0" },
  { text: "관리자", value: "1" },
  { text: "개발자", value: "3" },
];

const WORK_YN = [
  { text: "사용", value: "Y" },
  { text: "중지", value: "N" },
];

const PAY_STATUS = [
  { text: "대기중", value: "R" },
  { text: "결제완료", value: "P" },
  { text: "일부결제", value: "P1" },
  { text: "일부취소", value: "C1" },
  { text: "전체취소", value: "C" },
];

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

//테이블의 헤더 영역(타이틀)
const headCells = [
  {
    field: "pmCreateDate", //데이터 필드명
    headerName: "생성일자", //설명 글
    numeric: false, //숫자 여부
    disablePadding: false, //각 셀별 padding 값
    valueGetter: (
      params //값 setting 방법
    ) => util.getDateToFormat(new Date(params.data), "YYYY-MM-DD"),
  },
  {
    field: "pmCompanyName",
    headerName: "거래처상호",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "pmProductName",
    headerName: "서비스상품명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "pmTotalCost",
    headerName: "총금액",
    type: "string",
    minWidth: 150,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },

  {
    field: "paid_status",
    headerName: "결제상태",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => util.getCodeName(params.data, PAY_STATUS),
    renderCell: (params) => (
      <span
        className={
          params.data === "P"
            ? "text-primary"
            : params.data === "C"
            ? "text-danger"
            : params.data === "P1"
            ? "text-info"
            : params.data === "C1"
            ? "text-warning"
            : ""
        }
      >
        {util.getCodeName(params.data, PAY_STATUS)}
      </span>
    ),
  },
  {
    field: "paid_status",
    headerName: "삭제",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {(params.currentData.paid_status === "R" ||
          params.currentData.paid_status === "") && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger btn-sm ml-2"
            onClick={(e) => params.deleteOrder(e, params)}
            disabled={params.cancelBillStatus}
          >
            <i className="fas fa-times " />
            {params.cancelBillStatus && (
              <span
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        )}
      </span>
    ),
  },
  {
    field: "pmFiles",
    headerName: "첨부파일",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {params.currentData.pmFiles !== "" &&
          params.currentData.pmFiles.split("|").map((v, i) => {
            let splitData = v.split(":");
            if (splitData.length === 1) return null;
            let fileName = splitData[0];
            let fileLink = `//cardlogis.com/uploadFiles/attach/${splitData[1]}`;
            return (
              <div className="mb-2" key={`prev_${v}`}>
                <a
                  className="btn btn-sm btn-info mr-3"
                  href={`//cardlogis.com/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                  target="_blank"
                >
                  <i className="fa fa-download mr-2" /> Down
                </a>
                {fileName.endsWith(".pdf") && (
                  <a
                    className="font-weight-bold text-decoration-underline"
                    href={fileLink}
                    download={fileName}
                    target="_blank"
                  >
                    {fileName}
                  </a>
                )}
                {!fileName.endsWith(".pdf") && (
                  <a
                    className="font-weight-bold text-decoration-underline"
                    href={`//cardlogis.com/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                    target="_blank"
                  >
                    {fileName}
                  </a>
                )}
              </div>
            );
          })}
      </span>
    ),
  },
];

function Row(props) {
  const { row, index, openEdit, isMultiSelect = false, deleteOrder } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  deleteOrder: deleteOrder,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  deleteOrder: deleteOrder,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세결제내역
              </Typography>
              <PaymentRequestTableSub
                rowData={row}
                isServerPaging={false}
                isServerSort={false}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

/*        <TableCell component="th" scope="row">
          {row.field}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>*/

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

//테이블 헤드 생성자
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    console.log("createSortHandler");
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

//테이블 전체 생성자
export default function PaymentRequestTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const [userInfo, setUserInfo] = useState({ uLevel: "0", uID: null });
  const [cancelBillStatus, setCancelBillStatus] = useState(false);

  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("pmCreateDate");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "pmCreateDate",
      sort: "desc",
    },
  ]);
  let filters = {};

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    //console.log("getLocalDataSet EVENT_LOGIN_AUTO_SUCCESS", userdata);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    //getStatisticsData();
    //filterChanged();

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "PaymentRequestTable",
      (b, n) => {
        //console.log("HeaderAccController EVENT_LOGIN_AUTO_SUCCESS", n);
        if (n.ret) {
          setUserInfo({ ...n["returnData"][0] });

        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_FILTER_CHANGED,
      "PaymentRequestTable",
      (b, n) => {
        //console.log(n);
        filters = n;
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
      "PaymentRequestTable",
      (b, n) => {
        requestUserSelect();
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_FILTER_CHANGED,
        "PaymentRequestTable"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
        "PaymentRequestTable"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "PaymentRequestTable"
      );
    };
  }, []);

  useEffect(() => {
    //console.log("useEffect [page, sortModel]", page, sortModel);
    requestUserSelect();
  }, [page, sortModel,userInfo]);

  const requestUserSelect = () => {
    //console.log("requestUserSelect", userInfo);

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/payment/JS_payment_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    console.log(userInfo);
    if(!userInfo.uID) {
      let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
      //console.log("getLocalDataSet EVENT_LOGIN_AUTO_SUCCESS", userdata);
      if (userdata) {
        if (userdata.ret) {
          setUserInfo({ ...userdata["returnData"][0] });
        }
      }
      return;
    }

    socketMsg.uID = userInfo.uLevel != "0" ? "":userInfo.uID;



    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          //let returnData = newData["ReturnData"];
          //newData.data = newData["ReturnData"];
          //newData["ReturnData"] = [];
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );

          //console.log(ServerData);
          socket.sendLocalMessage(
            MsgIDList.EVENT_PAYMENT_LIST_SUB_NEED_REFRESH,
            "PaymentRequestTable",
            {},
            () => {}
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    console.log("useMemo");
    return [...serverData];
  }, [serverData]);

  /*
  const onFilterChange = React.useCallback((filterModel) => {
    setFilterValue(filterModel.items[0].value);
  }, []);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    console.log("handlePageChange", newPage);
    setPage(newPage);
  };
  const handleSortModelChange = (model) => {
    // We have the cursor, we can allow the page transition.
    console.log("handleSortModelChange", model);
    setSortModel(model);
  };
*/

  /*  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };*/

  const handleChangePage = (event, newPage) => {
    console.log(event, newPage);
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalCount.length - page * rowsPerPage);*/
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  // 항목 추가 이벤트 Amber
  function openEdit(e, params) {
    //console.log("openEdit", e.target.dataset, params);
    editModal.current.openModal(e, params);
  }

  const deleteOrder = (e, params) => {
    //setLoading(true);
    codeList.Modal.current.confirm(
      "선택한 건의 결제요청서를 모두 삭제하시겠습니까?",
      (ret) => {
        if (ret === true) {
          setCancelBillStatus(true);
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/payment/JS_payment_request_main_update.php",
            msgID: msgID,
            data: {},

            pmOderID: params.currentData.pmOderID,
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            console.log(newData);
            setCancelBillStatus(false);
            if (newData) {
              console.log(newData["ret"]);
              if (newData["ret"] === true) {
                setLoading(false);
                socket.sendLocalMessage(
                  MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
                  "PaymentRequestTableSub",
                  newData,
                  () => {}
                );
                codeList.Modal.current.alert(
                  `결제 요청서가 정상적으로 삭제되었습니다.`,
                  () => {}
                );

                params.currentData.paid_status = "C";
                requestUserSelect();
              } else {
                setLoading(false);
                codeList.Modal.current.alert(
                  `결제요청서 삭제 중 오류가 발생하였습니다. 관리자에게 문의 해주세요.`,
                  () => {}
                );
              }
            }
          });
        }
      }
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" id="paymentRequestList">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  deleteOrder={deleteOrder}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                openEdit={openEdit}
                deleteOrder={deleteOrder}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            console.log(value - 1); //apiRef.current.setPage(value - 1)
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

PaymentRequestTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
