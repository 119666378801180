import React, { useState } from "react";

function UserPayment() {
  return (
    <>
      <div className="content">
        <div className="container py-3 shadow" style={{ maxWidth: 600 }}>
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto">
              <h3>
                <strong>결제요청 정보</strong>
              </h3>
            </div>
          </div>
          {/**/}
          <div className="row bg-white">
            <div className="col">
              {/*요약 정보*/}
              <div className="container bg-primary my-3 py-3 text-white">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">결제요청자</div>
                      <div className="col">(주)카드로지스</div>
                    </div>
                    <div className="row my-2">
                      <div className="col">상품(서비스)명</div>
                      <div className="col">8월해상운임비</div>
                    </div>
                    <div className="row">
                      <div className="col">결제요청금액</div>
                      <div className="col">9,075,330 원</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*금액상세*/}
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>세금종류</th>
                    <th>적요</th>
                    <th className="text-end">공급가액</th>
                    <th className="text-end">부가세</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>과세</td>
                    <td>내륙운송료 외</td>
                    <td className="text-end">8,250,300</td>
                    <td className="text-end">825,030</td>
                  </tr>

                  <tr>
                    <th>&nbsp;</th>

                    <th className="font-weight-bold">소계</th>
                    <td className="font-weight-bold text-end">8,250,300</td>
                    <td className="font-weight-bold text-end">825,030</td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="font-weight-bold">합계</th>
                    <th>&nbsp;</th>
                    <td className="font-weight-bold text-end">9,075,330</td>
                  </tr>
                  <tr>
                    <th colSpan={4}>
                      비고사항입니다 비고비고비고비고비고비고비고 비고사항입니다
                      비고비고비고비고비고비고비고 비고사항입니다
                      비고비고비고비고비고비고비고 비고사항입니다
                      비고비고비고비고비고비고비고
                    </th>
                  </tr>
                </tbody>
              </table>

              {/*  기타정보, 첨부파일*/}
              <div className="container my-2">
                <div className="row align-items-center">
                  <div className="col-12 pb-2">담당자 전화번호</div>
                  <div className="col-12 pb-2">
                    <input
                      type="text"
                      placeholder="결제 담당자 전화번호 입력"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 pb-2">결제기업 상호</div>
                  <div className="col-12 pb-1">
                    <input
                      type="text"
                      placeholder="결제기업 상호명 입력"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row py-3 align-items-center">
                  <a className="col-12 font-weight-bold text-decoration-underline">
                    명세서.pdf
                  </a>
                  <a className="col-12 font-weight-bold text-decoration-underline">
                    첨부파일.pdf
                  </a>
                </div>

                <div className="row">
                  <div className="col bg-light p-2">
                    8월 해상운임 마감분입니다. 항공건 별도 청구합니다.
                  </div>
                </div>
              </div>
              {/*  약관*/}
              <div className="container">
                <div className="row py-3 align-items-center border mb-3">
                  <div className="col-12">
                    <input id="personalInformation" type="checkbox" />
                    <label className="m-0" htmlFor="personalInformation">
                      개인정보 수집 및 이용동의
                      <span className="text-primary">(필수)</span>
                    </label>
                    <span
                      className="btn p-0 ml-2 font-weight-bold"
                      style={{
                        fontSize: "0.8rem",
                        textDecoration: "underline",
                      }}
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      자세히 보기
                    </span>
                    <div className=" collapse" id="collapseExample">
                      <div
                        className="container py-2 bg-light"
                        style={{
                          height: "200px",
                          overflow: "scroll",
                        }}
                      >
                        <p className="font-weight-bold">
                          수집하는 개인정보의 항목
                        </p>
                        <p>
                          회사는 카드로지스에서 제공하는 전자결제요청서비스(이하
                          “ 서비스”라 칭함)를 이용하여 대금의 결제요청, 취소,
                          환불 및 이와관련한 문의사항 등, 서비스의 제공을 위하여
                          아래와 같은 개인정보를 수집하고 있습니다.
                        </p>
                        <div className="container">
                          <div>
                            <p>1. 개인정보 수집항목</p>
                            <div className="container">
                              <div>
                                <p>1-1. 필수항목</p>
                                <p>
                                  · 결제자의 이름, 이메일, 전화번호, 담당자명,
                                  담당자 휴대폰번호, 담당자 이메일주소
                                </p>
                                <p>
                                  · 상기 명시된 개인정보 항목 이외의 '서비스'
                                  이용과정이나 '서비스' 처리과정에서 다음과 같은
                                  추가 정보들이 자동 혹은 수동으로 생성되어 수집
                                  될 수 있습니다. 접속 IP/MAC Address, 쿠키,
                                  e-mail, 서비스 접속 일시, 서비스 이용기록,
                                  불량 혹은 비정상 이용기록, 결제기록{" "}
                                </p>
                                <p>
                                  · 기타 회사는 서비스 이용과 관련한 대금결제,
                                  환불 등에 필요한 다음과 같은 정보 등을 추가로
                                  수집할 수 있습니다. 계좌번호, 예금주명,
                                  서비스계약일 등
                                </p>
                              </div>
                              <div>
                                <p>1-2. 선택항목</p>
                                <p>
                                  · 필수항목 이외에 계약서류에 기재된 정보 또는
                                  고객이 제공한 정보
                                </p>
                                <p>· 주소, 팩스번호 등</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>2. 수집방법</p>
                            <div className="container">
                              <div>
                                <p>
                                  2-1. 결제요청서에 고객이 기입하여 제시하는
                                  개인정보 및 해당건과 관련하여 이메일등 별도의
                                  수단을 이용하여 제공하는 개인정보를 수집
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="font-weight-bold">
                          개인정보의 수집 이용목적
                        </p>
                        <p>
                          회사는 수집한 개인정보를 다음의 목적을 위해
                          활용합니다.
                        </p>
                        <div className="container">
                          <div>
                            <p>
                              1. 서비스 제공 계약의 성립, 유지, 종료를 위한 본인
                              식별 및 실명확인, 각종 회원관리, 계약서 발송 등
                            </p>
                          </div>
                          <div>
                            <p>
                              2. 서비스 제공 과정 중 본인 식별, 인증, 실명확인
                              및 각종 안내/고지
                            </p>
                          </div>
                          <div>
                            <p>3. 부정 이용방지 및 비인가 사용방지</p>
                          </div>
                          <div>
                            <p>
                              4. 서비스 제공 및 관련 업무처리에 필요한 동의 또는
                              철회 등 의사확인
                            </p>
                          </div>
                          <div>
                            <p>
                              5. 이용 빈도 파악 및 인구통계학적 특성에 따른
                              서비스 제공 및 CRM
                            </p>
                          </div>
                          <div>
                            <p>
                              6. 서비스 제공을 위한 각 결제수단 별 상점 사업자
                              정보 등록
                            </p>
                          </div>
                          <div>
                            <p>
                              7. 기타 회사가 제공하는 이벤트 등 광고성 정보
                              전달, 통계학적 특성에 따른 서비스 제공 및 광고
                              게재, 실제 마케팅 활동
                            </p>
                          </div>
                        </div>
                        <p className="font-weight-bold">
                          개인정보의 보유 및 이용기간
                        </p>
                        <p>
                          이용자의 개인정보는 원칙적으로 개인정보의 수집 및
                          이용목적이 달성되면 지체 없이 파기 합니다. 단, 다음의
                          각 목에 해당하는 정보에 대해서는 아래의 이유로 명시한
                          기간 동안 보존 합니다.
                        </p>
                        <div className="container">
                          <div>
                            <p>1. 회사 내부 방침의 의한 정보보유</p>
                            <div className="container">
                              <div>
                                <p>
                                  1-1. 보존항목: 서비스 상담 수집 항목(회사명,
                                  고객명, 전화번호, E-mail, 상담내용 등)
                                </p>
                              </div>
                              <div>
                                <p>
                                  1-2. 보존이유: 분쟁이 발생 할 경우 소명자료
                                  활용
                                </p>
                              </div>
                              <div>
                                <p>1-3. 보존기간: 상담 완료 후 6개월</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>2. 관련법령에 의한 정보보유</p>
                            <div className="container">
                              <div>
                                <p>2-1. 계약 또는 청약철회 등에 관한 기록</p>
                                <p>· 보존기간: 5년</p>
                                <p>
                                  · 보존근거: 전자상거래 등에서의 소비자보호에
                                  관한 법률
                                </p>
                              </div>
                              <div>
                                <p>
                                  2-2. 대금결제 및 재화 등의 공급에 관한 기록
                                </p>
                                <p>· 보존기간: 5년</p>
                                <p>
                                  · 보존근거: 전자상거래 등에서의 소비자보호에
                                  관한 법률
                                </p>
                              </div>
                              <div>
                                <p>
                                  2-3. 소비자의 불만 또는 분쟁처리에 관한 기록
                                </p>
                                <p>· 보존기간: 3년</p>
                                <p>
                                  · 보존근거: 전자상거래 등에서의 소비자보호에
                                  관한 법률
                                </p>
                              </div>
                              <div>
                                <p>2-4. 본인확인에 관한 기록</p>
                                <p>· 보존기간: 6개월</p>
                                <p>
                                  · 보존근거: 정보통신 이용촉진 및 정보보호 등에
                                  관한 법률
                                </p>
                              </div>
                              <div>
                                <p>2-5. 방문에 관한 기록</p>
                                <p>· 보존기간: 3개월</p>
                                <p>· 보존근거: 보존근거: 통신비밀보호법</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="btn btn-outline-info">다음</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPayment;
