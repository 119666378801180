import React, {useEffect, useState} from "react";
import CardLogisLoginModal from "../homePage/CardLogisLoginModal";
import CodeList from "../lib/CodeList";
import CardLogisPartnerModal from "./CardLogisPartnerModal";
import BuddibleSocket, {MsgIDList} from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
let codeList = new CodeList();

const AGENT_LEVEL = [
    {text: "포워더", value: "0"},
    {text: "관세사", value: "1"},
    {text: "개발자", value: "3"},
];

export default function CardLogisPartner() {
    let [partners, setPartners] = useState([]);
    let [partnerDetail, setPartnerDetail] = useState({
        bIDX: "1",
        bLogo: "logo_buddible_200.png",
        bBranchName: "버디블",
        bBranchTYPE: "0",
        bCEOName: "윤성준",
        bBranchDate: "2020-09-19",
        bUserName: "담당자",
        bTel: "070-5088-0123",
        bEmail: "buddible@buddible.com",
        bService: "소프트웨어 개발업",
        bMemo:
            "안녕하십니까.\n저희는웹& 앱플랫폼서비스를제공하는회사입니다.\n\n- 고객의니즈와방향성을함께고민한다.\n- 더나은방향과목표를고객과함께한다.\n- 고객이믿고맡길수있도록신뢰감을줘야한다.\n\n위의세가지항목을중심으로고객과함께성장하는플랫폼개발회사가되도록하겠습니다.",
        bAddrFull: null,
        bAddrRoadAddress: "경기 고양시 일산동구 숲속마을1로 26",
        bAddrSido: null,
        bAddrSigungu: null,
        bAddrRoadname: null,
        bAddrKey: null,
        bMapTime: "1632023771600",
        bMapKey: "27f4w",
        bShowYN: "Y",
        bOrder: "0",
    });
    let [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        requestBranchSelect();
    }, []);

    const requestBranchSelect = () => {
        let msgID = util.makeUUIDv4();
        let socketMsg = {
            file: "/branch/JS_branch_select_by_home.php",
            msgID: msgID,
            data: {},
        };

        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
                if (newData["ret"]) {
                    setPartners(newData["returnData"]);
                }
            }
        });
    };

    const partnerModalOpen = (data) => {
        setPartnerDetail({...data});
        setModalOpen(true);
        socket.sendLocalMessage(
            MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
            "CardLogisPartner",
            {...data},
            () => {
            }
        );
    };

    return (
        <>
            {modalOpen && <CardLogisPartnerModal partnerDetail={partnerDetail}/>}
            <section className="design-team section bg-gray" id="cardLogisPartner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2 className="font-weight-bold">카드로지스 가맹점 현황</h2>
                                <h5>
                                    카드로지스를 통하여 카드결제가 가능한 포워더, 관세사, 운송사와
                                    창고를 소개합니다.
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        {partners.length && partners.map((partner, index) => {
                            return (
                                <div className="col mb-4" key={index}>
                                    <div className="team-sm card h-100 shadow-sm border-0">
                                        <div className="card-body position-relative">
                                            <div
                                                className="image"
                                                style={{
                                                    width: "100%",
                                                    height: "125px",
                                                    background: `url(//cardlogis.com/uploadFiles/logo/${partner.bLogo}) 50% 50% / contain no-repeat`,
                                                }}
                                            />
                                            <div>
                                                <h3 className="d-inline-block">
                                                    {partner.bBranchName}
                                                </h3>
                                                <span>({partner.bService})</span>
                                            </div>
                                            <p>{partner.bAddrRoadAddress} </p>
                                            <p className="crop-text-5">{partner.bMemo}</p>
                                        </div>
                                        <div className="card-footer bg-transparent border-0">
                                            <button
                                                className="social-links w-100 btn btn-primary" // position-relative bottom-0
                                                data-toggle="modal"
                                                data-target="#partnerDetailModal"
                                                onClick={() => {
                                                    partnerModalOpen({...partner});
                                                    /*setPartnerDetail({ ...partner });
                                                          setModalOpen(true);
                                                          socket.sendLocalMessage(
                                                            MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
                                                            "CardLogisPartner",
                                                            { ...partner },
                                                            () => {}
                                                          );*/
                                                }}
                                            >
                                                자세히보기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}
