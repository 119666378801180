import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="row px-4">
        <div className="col-12">
          <h4
            className="font-weight-bold py-4 mb-4"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            개인정보수집 및 이용동의 약관
          </h4>
        </div>
        <div className="col-12 py-4">
          <h3 className="text-secondary font-weight-bold">
            수집하는 개인정보의 항목
          </h3>
          <p className="py-3">
            회사는 카드로지스에서 제공하는 전자결제요청서비스(이하 “ 서비스”라
            칭함)를 이용하여 대금의 결제요청, 취소, 환불 및 이와관련한 문의사항
            등, 서비스의 제공을 위하여 아래와 같은 개인정보를 수집하고 있습니다.
          </p>
          <ol className="pb-3">
            <li>
              <p>개인정보 수집항목</p>
              <ol>
                <li>
                  <p>필수항목</p>
                  <ol className="sub_list">
                    <li>
                      결제자의 이름, 이메일, 전화번호, 담당자명, 담당자
                      휴대폰번호, 담당자 이메일주소.
                    </li>
                    <li>
                      상기 명시된 개인정보 항목 이외의 “서비스” 이용과정이나
                      “서비스” 처리 과정에서 다음과 같은 추가 정보들이 자동 혹은
                      수동으로 생성되어 수집 될 수 있습니다. 접속 IP/MAC
                      Address, 쿠키, e-mail, 서비스 접속 일시, 서비스 이용기록,
                      불량 혹은 비정상 이용기록, 결제기록
                    </li>
                    <li>
                      기타 회사는 서비스 이용과 관련한 대금결제, 환불 등에
                      필요한 다음과 같은 정보 등을 추가로 수집할 수 있습니다.
                      계좌번호, 예금주명, 서비스계약일 등
                    </li>
                  </ol>
                </li>
                <li>
                  <p>선택항목</p>
                  <ol className="sub_list">
                    <li>
                      필수항목 이외에 계약서류에 기재된 정보 또는 고객이 제공한
                      정보
                    </li>
                    <li>주소, 팩스번호 등</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>수집방법</p>
              결제요청서에 고객이 기입하여 제시하는 개인정보 및 해당건과
              관련하여 이메일등 별도의 수단을 이용하여 제공하는 개인정보를 수집
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <h3 className="text-secondary font-weight-bold">
            개인정보의 수집 이용 목적
          </h3>
          <p className="py-3">
            회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
          </p>
          <ol className="pb-3">
            <li>
              <p>
                서비스 제공 계약의 성립, 유지, 종료를 위한 본인 식별 및
                실명확인, 각종 회원관리, 계약서 발송 등
              </p>
            </li>
            <li>
              <p>
                서비스 제공 과정 중 본인 식별, 인증, 실명확인 및 각종 안내/고지
              </p>
            </li>
            <li>
              <p>부정 이용방지 및 비인가 사용방지</p>
            </li>
            <li>
              <p>
                서비스 제공 및 관련 업무처리에 필요한 동의 또는 철회 등 의사확인
              </p>
            </li>
            <li>
              <p>
                이용 빈도 파악 및 인구통계학적 특성에 따른 서비스 제공 및 CRM
              </p>
            </li>
            <li>
              <p>서비스 제공을 위한 각 결제수단 별 상점 사업자 정보 등록</p>
            </li>
            <li>
              <p>
                기타 회사가 제공하는 이벤트 등 광고성 정보 전달, 통계학적 특성에
                따른 서비스 제공 및 광고 게재, 실제 마케팅 활동
              </p>
            </li>
          </ol>
        </div>

        <div className="col-12 py-4">
          <h3 className="text-secondary font-weight-bold">
            개인정보의 보유 및 이용기간
          </h3>
          <p className="py-3">
            이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
            달성되면 지체 없이 파기 합니다. 단, 다음의 각 목에 해당하는 정보에
            대해서는 아래의 이유로 명시한 기간 동안 보존 합니다.
          </p>
          <ol className="pb-3">
            <li>
              <p>회사 내부 방침의 의한 정보보유</p>
              <ol>
                <li>
                  <p>
                    보존항목: 서비스 상담 수집 항목(회사명, 고객명, 전화번호,
                    E-mail, 상담내용 등)
                  </p>
                </li>
                <li>
                  <p>보존이유: 분쟁이 발생 할 경우 소명자료 활용</p>
                </li>
                <li>
                  <p>보존기간: 상담 완료 후 6개월</p>
                </li>
              </ol>
            </li>
            <li>
              <p>관련법령에 의한 정보보유</p>
              상법, 전자상거래 등에서의 소비자보호에 관한 법률, 전자금융거래법
              등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
              관련법령에서 정한 일정한 기간 동안 정보를 보관합니다. 이 경우
              회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
              다음 각 호와 같습니다.
              <ol>
                <li>
                  계약 또는 청약철회 등에 관한 기록
                  <ol className="sub_list">
                    <li>보존근거 : 전자상거래 등에서의 소비자보홍 관한 법률</li>
                    <li>보존기간 : 5년</li>
                  </ol>
                </li>
                <li>
                  대금결제 및 재화 등의 공급에 관한 기록
                  <ol className="sub_list">
                    <li>보존기간 : 5년</li>
                    <li>보존근거 : 전자상거래 등에서의 소비자보홍 관한 법률</li>
                  </ol>
                </li>
                <li>
                  소비자의 불만 또는 분쟁처리에 관한 기록
                  <ol className="sub_list">
                    <li>보존기간 : 3년</li>
                    <li>보존근거 : 전자상거래 등에서의 소비자보홍 관한 법률</li>
                  </ol>
                </li>
                <li>
                  본인확인에 관한 기록
                  <ol className="sub_list">
                    <li>보존기간 : 6개월</li>
                    <li>
                      보존근거 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
                    </li>
                  </ol>
                </li>
                <li>
                  방문에 관한 기록
                  <ol className="sub_list">
                    <li>보존기간 : 3개월</li>
                    <li>보존근거 : 통신비밀보호법</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
