import React from "react";

export default function CardInfo() {
  return (
    <>
      <div
        className="container mt-3 shadow p-4"
        id="cardInfo"
        style={{ maxWidth: "640px" }}
      >
        <div
          className="row m-0 p-4"
          style={{
            height: "640px",
            background: `url(./images/cardinfo_background.png) 50% 50%/cover no-repeat`,
          }}
        >
          <div className="col-12 p-0">
            <h1 className="font-weight-bold text-center pt-4 px-3 cardinfo_title">
              수출입 물류비, 통관비 편리하게 납부하고 한도없이 0.5% 캐시백!!
            </h1>
          </div>
          <div className="col-12 p-0">
            <div
              className="service-thumb align-items-center text-center"
              data-aos="fade-right"
            >
              <img
                className="img-fluid"
                id="introCardImg"
                src="images/samsung_card(dark).png"
                alt="samsung_card"
              />
            </div>
          </div>

          <div className="col-12">
            <div className="row d-flex justify-content-center cardInfo_btn_wrap">
              <a
                className="btn btn-primary font-weight-bold rounded-pill px-3 mx-2 mb-1"
                style={{
                  fontSize: "1.5em",
                }}
                href="http://www.samsungcard.com/corporation/card/cor-biz-card/UHPCCA0211M0.jsp?fncPdC=AA19095"
              >
                법인사업자 카드 신청하기
              </a>
              <a
                className="btn btn-primary font-weight-bold rounded-pill px-3 mx-2 mb-1"
                style={{
                  fontSize: "1.5em",
                }}
                href="http://www.samsungcard.com/corporation/card/biz-card/UHPCCA0201M0.jsp?fncPdC=AA19095"
              >
                개인사업자 카드 신청하기
              </a>
              <a
                className="btn btn-primary font-weight-bold rounded-pill px-3 mx-2 mb-1"
                style={{
                  fontSize: "1.5em",
                }}
                href="http://www.samsungcard.com/corporation/customer-service/email/newbie/UHPCCC0104M0.jsp?[CARDLOGIS]"
              >
                상담 | 문의
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 p-4 h4"
            style={{
              lineHeight: "1.5em",
              wordBreak: "keep-all",
              whiteSpace: "break-spaces",
              fontSize: "1.5em",
            }}
          >
            <ul className="cardInfo_detail_wrap" style={{ color: "#6c757d" }}>
              <li>
                - 전월 이용금액에 관계없이, 캐시백한도 없이 국내외 이용금액의
                0.5%가 캐시백됩니다.
              </li>
              <li>- 연회비 : 해외겸용(Mastercard) 없음 ㅣ 국내전용 없음</li>
              <li  className="font-weight-bold"
                   style={{ fontSize: "1.1em", color: "#444444" }}>
                - 연체이자율 : 회원별·이용상품별 정상이자율 + 3%p(최고 연 20.0%)
                <ul>
                  <li>
                    ㆍ연체 발생 시점에 정상이자율이 없는 경우 아래와 같이 적용
                  </li>
                  <li>
                    ① 일시불 거래 연체 시 : 거래 발생 시점의 최소 기간(2개월)
                    유이자할부 이자율
                  </li>
                  <li>
                    ② 무이자할부 거래 연체 시 : 거래 발생 시점의 동일한 할부
                    계약기간 유이자할부 이자율
                  </li>
                </ul>
              </li>
              <li>
                - 금융상품 이용 전 상품설명서, 홈페이지, 약관을 통해 이용조건을
                확인해 주시기 바랍니다.
              </li>
              <li>
                - 필요 이상으로 신용카드를 발급 및 이용하실 경우 개인신용평점,
                이용한도 등에 영향을 미칠 수 있습니다.
              </li>
              <li>
                - 금융소비자는 해당 상품 또는 서비스에 대하여 설명을 받을 권리가
                있습니다.
              </li>
              <li>
                - 신용카드 발급이 부적정한 경우(개인신용평점 낮음 등) 카드
                발급이 제한될 수 있습니다.
              </li>
              <li>
                - 카드 이용대금과 이에 수반되는 모든 수수료를 지정된 대금
                결제일에 상환합니다.
              </li>
              <li
                className="font-weight-bold"
                style={{ fontSize: "1.1em", color: "#444444" }}
              >
                - 상환 능력에 비해 신용카드 사용액이 과도할 경우 귀하의
                개인신용평점이 하락할 수 있습니다.
              </li>
              <li
                className="font-weight-bold"
                style={{ fontSize: "1.1em", color: "#444444" }}
              >
                - 개인신용평점 하락 시 금융거래 관련된 불이익이 발생할 수
                있습니다.
              </li>
              <li
                className="font-weight-bold"
                style={{ fontSize: "1.1em", color: "#444444" }}
              >
                - 일정 기간 원리금을 연체할 경우, 모든 원리금을 변제할 의무가
                발생할 수 있습니다.
              </li>
              <li>
                - 카드로지스는 삼성카드와 회원모집 위탁 계약을 체결한
                금융상품 판매대리·중개업자로서 삼성카드의 전속업체는 아닙니다.
                카드로지스는 금융관계법률에 따라 등록되어 있으며 삼성카드
                홈페이지에서 회원모집 위탁 계약내용을 확인하실 수 있습니다.
              </li>
              <li>
                - 카드로지스는 삼성카드의 금융상품에 관한 계약 체결권한은 없으며
                고객님께 계약 체결에 따른 급부를 받거나 삼성카드로부터 정해진
                수수료외 금품 등을 받을 수 없습니다.
              </li>
              <li>
                -고객님께서 제공한 개인신용정보등은 삼성카드가 보유 관리하지만,
                카드로지스의 고의·과실로 금융소비자보호법(설명의무가 있는
                경우에는 동법 제19조 설명의무 포함)을 위반하여 고객님께 손해가
                발생된 경우는 카드로지스가 손해배상 책임을 집니다.
              </li>
              <li>- 준법심의필 I3401-22-4779 (2022.10.19 ~ 2023.10.18)</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
