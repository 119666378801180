import React, { useState } from "react";

function CardLogisLoginModal({ isLogin, setIsLogin }) {
  return (
    <div
      className="modal fade "
      id="loginModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              카드로지스 로그인
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <form className="row gy-2 gx-3 align-items-center">
              <div className="col-12">
                <label
                  className="visually-hidden"
                  htmlFor="autoSizingInputGroup"
                >
                  Username
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="autoSizingInput"
                    placeholder="아이디를 입력해주세요"
                  />
                </div>
              </div>
              <div className="col-12">
                <input
                  className="form-control"
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                />
              </div>
              <div className="col-12 ">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="autoSizingCheck"
                  />
                  <label className="form-check-label" htmlFor="autoSizingCheck">
                    아이디 저장
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              닫기
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => setIsLogin(!isLogin)}
            >
              로그인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardLogisLoginModal;
