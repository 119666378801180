import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import PaymentRequestTable from "./UserManagementTable";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function UserManagementEdit(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const $inputID = useRef();
  const $inputPWD = useRef();
  const $inputPWDRe = useRef();
  const $inputName = useRef();
  const $inputTel = useRef();
  const $inputEmail = useRef();
  const $inputEtc = useRef();
  const $inputLevel = useRef();
  const $inputJoinDate = useRef();
  const $inputWorkYN = useRef();

  const uCoName = useRef();
  const uCoCEOName = useRef();
  const uCoAddr = useRef();
  const uCoRegNo = useRef();
  const uCoTel = useRef();
  const uPGIDSamsung = useRef();
  const uPGIDNoneSamsung = useRef();
  const uPGKeySamsung = useRef();
  const uPGKeyNoneSamsung = useRef();

  const dataChange = (e, params) => {
    console.log(userData, params);
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $inputID.current.value = util.buddibleDecrypt(userData.currentData.uID);
      $inputID.current.disabled = true;
      $inputName.current.value = userData.currentData.uName;
      $inputTel.current.value = userData.currentData.uTel;
      $inputEmail.current.value = userData.currentData.uEmail;
      $inputEtc.current.value = userData.currentData.uEtc;
      $inputLevel.current.value = userData.currentData.uLevel;
      $inputWorkYN.current.value = userData.currentData.uWorkYN;
      $inputJoinDate.current.value = userData.currentData.uJoinDate;
      uCoName.current.value = userData.currentData.uCoName;
      uCoCEOName.current.value = userData.currentData.uCoCEOName;
      uCoAddr.current.value = userData.currentData.uCoAddr;
      uCoRegNo.current.value = userData.currentData.uCoRegNo;
      uCoTel.current.value = userData.currentData.uCoTel;
      uPGIDSamsung.current.value = userData.currentData.uPGIDSamsung;
      uPGIDNoneSamsung.current.value = userData.currentData.uPGIDNoneSamsung;
      uPGKeySamsung.current.value = userData.currentData.uPGKeySamsung;
      uPGKeyNoneSamsung.current.value = userData.currentData.uPGKeyNoneSamsung;
    } else {
      $inputID.current.value = "";
      $inputID.current.disabled = false;
      $inputName.current.value = "";
      $inputName.current.value = "";
      $inputTel.current.value = "";
      $inputEmail.current.value = "";
      $inputEtc.current.value = "";
      $inputLevel.current.value = "";
      $inputWorkYN.current.value = "";
      $inputJoinDate.current.value = "";
      $inputPWD.current.value = "";
      $inputPWDRe.current.value = "";

      uCoName.current.value = "";
      uCoCEOName.current.value = "";
      uCoAddr.current.value = "";
      uCoRegNo.current.value = "";
      uCoTel.current.value = "";
      uPGIDSamsung.current.value = "";
      uPGIDNoneSamsung.current.value = "";
      uPGKeySamsung.current.value = "";
      uPGKeyNoneSamsung.current.value = "";
    }
  }, [userData]);

  useEffect(() => {
    let isIE = window.ActiveXObject || "ActiveXObject" in window;
    if (isIE) {
      $(".modal").removeClass("fade");
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      "UserManagementEdit",
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
        "UserManagementEdit"
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    console.log(codeList.Modal.current);
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        uID: util.buddibleEncrypt($inputID.current.value),
        uName: $inputName.current.value,
        uTel: $inputTel.current.value,
        uEmail: $inputEmail.current.value,
        uJoinDate: $inputJoinDate.current.value,
        uEtc: $inputEtc.current.value,
        uWorkYN: $inputWorkYN.current.value,
        uCoName: uCoName.current.value,
        uCoCEOName: uCoCEOName.current.value,
        uCoAddr: uCoAddr.current.value,
        uCoRegNo: uCoRegNo.current.value,
        uCoTel: uCoTel.current.value,
        uPGIDSamsung: uPGIDSamsung.current.value,
        uPGIDNoneSamsung: uPGIDNoneSamsung.current.value,
        uPGKeySamsung: uPGKeySamsung.current.value,
        uPGKeyNoneSamsung: uPGKeyNoneSamsung.current.value,
        uLevel: $inputLevel.current.value,
      };
      if ($inputPWD.current.value.trim() !== "") {
        updateData["uPWD"] = util.buddibleEncrypt(
          $inputPWD.current.value.trim()
        );
      }

      console.log($inputID.current.disabled);
      requestUserUpdate(
        updateData,
        $inputID.current.disabled ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($inputID.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 아이디를 입력해주세요", () => {});
      return false;
    }
    console.log($inputID.current.disabled);

    if ($inputID.current.disabled === false) {
      console.log(
        $inputPWD.current.value.trim(),
        $inputPWDRe.current.value.trim(),
        $inputPWD.current.value.trim() === "" ||
          $inputPWDRe.current.value.trim() === "",
        $inputPWD.current.value.trim() === "",
        $inputPWDRe.current.value.trim() === ""
      );
      if (
        $inputPWD.current.value.trim() === "" ||
        $inputPWDRe.current.value.trim() === ""
      ) {
        codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
        return false;
      } else {
        if (
          $inputPWD.current.value.trim() !== $inputPWDRe.current.value.trim()
        ) {
          codeList.Modal.current.alert(
            "비밀번호가 다릅니다. 비밀번호 확인을 다시 입력해주세요",
            () => {}
          );
          return false;
        }
      }
    }
    if ($inputPWD.current.value.trim() !== "") {
      if ($inputPWDRe.current.value.trim() !== "") {
        if (
          $inputPWD.current.value.trim() !== $inputPWDRe.current.value.trim()
        ) {
          codeList.Modal.current.alert(
            "비밀번호가 다릅니다. 비밀번호 확인을 다시 입력해주세요",
            () => {}
          );
          return false;
        }
      } else {
        codeList.Modal.current.alert(
          "비밀번호 재확인을 입력해주세요",
          () => {}
        );
        return false;
      }
    }
    if ($inputLevel.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 권한을 선택 해주세요", () => {});
      return false;
    }
    if ($inputJoinDate.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 가입일을 선택 해주세요", () => {});
      return false;
    }
    if ($inputWorkYN.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디 사용여부를 선택 해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/account/JS_account_update.php"
          : "/account/JS_account_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);

      if (newData) {
        console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_USER_MANAGE_USER_UPDATED
              : MsgIDList.EVENT_USER_MANAGE_USER_INSERTED,
            "UserManagementEdit",
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "사용자 정보를 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "사용자 정보를 추가 하였습니다.",
                () => {}
              );

          $("#btnCloseUserEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `사용자 정보 업데이트 중 오류가 발생하였습니다.\n${newData.MSG}`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `사용자 정보 추가 중 오류가 발생하였습니다.\n${newData.MSG}`,
                () => {}
              );
        }
      }
    });
  };

  const makeShortenLink = () => {
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      { long_url: `https://www.cardlogis.com/UserPayment?link=${msgID}` },
      msgID,
      (beforeData, newData) => {
        console.log(newData);
      }
    );
  };

  return (
    <>
      <div
        className={"modal fade"}
        id="staticUserManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog" style={{ maxWidth: 1000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>계정 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseUserEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      아이디
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="account"
                        className="form-control"
                        placeholder="ID"
                        ref={$inputID}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row justify-content-end">
                    <label className="col-form-label col-sm-3 text-end">
                      비밀번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        ref={$inputPWD}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row justify-content-end">
                    <label className="col-form-label col-sm-3 text-end">
                      비밀번호 확인
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        ref={$inputPWDRe}
                      />
                    </div>
                    <label className="col-form-label col-sm-9 text-end text-danger">
                      입력시 비밀번호가 변경됩니다.
                    </label>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      담당자명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="User Name"
                        ref={$inputName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      담당자 전화번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Tel Number"
                        ref={$inputTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        ref={$inputEmail}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      가입일
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="date"
                        disabled={false}
                        ref={$inputJoinDate}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      비고
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        rows="3"
                        ref={$inputEtc}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      사용유무
                    </label>
                    <div className="col-sm-9">
                      <select className="form-control" ref={$inputWorkYN}>
                        <option value="">미선택</option>
                        <option value="Y">사용</option>
                        <option value="N">중지</option>
                      </select>
                    </div>
                  </div>
                  <fieldset className="mb-3">
                    <div className="row">
                      <label className="col-form-label col-sm-3 text-end pt-sm-0">
                        권한
                      </label>
                      <div className="col-sm-9">
                        <select className="form-control" ref={$inputLevel}>
                          <option value="">미선택</option>
                          <option value="0">가맹점</option>
                          <option value="1">관리자</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      회사명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사명"
                        ref={uCoName}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      대표자명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="대표자명"
                        ref={uCoCEOName}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      회사주소
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사주소"
                        ref={uCoAddr}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      사업자등록번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="사업자등록번호"
                        ref={uCoRegNo}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      회사연락처
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사연락처"
                        ref={uCoTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      전용카드 PG ID
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="전용카드 PG ID"
                        ref={uPGIDSamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      전용카드 PG 암호화 Key
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="전용카드 PG 암호화 Key"
                        ref={uPGKeySamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      일반 PG ID
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="일반 PG ID"
                        ref={uPGIDNoneSamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      일반 PG 암호화 Key
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="일반 PG 암호화 Key"
                        ref={uPGKeyNoneSamsung}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*, uCoName, uCoCEOName, uCoAddr, uCoRegNo, uCoTel, uPGIDSamsung, uPGIDNoneSamsung*/}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
              >
                <i className="fa fa-check mr-2" />
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(UserManagementEdit);
