import React from "react";

export default function TermsOfService() {
  return (
    <div className="container">
      <div className="row px-4">
        <div className="col-12">
          <h4
            className="font-weight-bold py-4 mb-4"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            서비스 이용약관
          </h4>
        </div>
        <div className="col-12">
          <h6 className="font-weight-bold">제1장 총칙</h6>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제1조 (목적)</p>
          <p>
            본 약관은 주식회사 지비티에스(이하 “회사”)가 제공하는 신용카드
            결제요청서 작성 및 관리솔루션(이하 “카드로지스”)서비스 및 이에
            부수된 제반 서비스(이하”서비스”)의 이용과 관련하여 회사와 회원간의
            권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            <br />
            <br />
            본, 서비스 이용약관에 동의하고 온라인으로 신청한 회원가입 신청은 각
            서비스의 종류에 따라 가입 및 서비스계약을 청약한 것으로 간주하며,
            회사의 승인은 계약의 성립을 여기에서 “서비스계약”은 본 약관 및
            조건을 의미합니다.
          </p>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제2조 (용어의 정의)</p>
          <div>
            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <ol className="py-2">
              <li>
                “회원”이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와
                이용계약을 체결하여 회사가 제공하는 서비스를 이용하는 고객을
                의미합니다.
              </li>
              <li>
                “카드로지스” 라 함은 이용자가 컴퓨터등 유, 무선 정보통신 설비를
                통하여 신용카드 결제요청서를 작성하고, 관리할 수 있는 신용카드
                결제관리 시스템을 말합니다.
              </li>
              <li>
                “서비스”라 함은 회사가 회원에게 제공하는 PG(전자결제가맹점)가입
                중개를 포함하여 “카드로”시스템의 제공 및 관리 이에 부수된 제반
                서비스를 의미합니다.
              </li>
              <li>
                “회원정보”라 함은 회원의 성명, 비밀번호, 이메일등 회원이
                생성하여 등록한 일반정보 및 서비스이용정보, 이용요금 결재상태등
                생성정보를 통칭합니다.
              </li>
            </ol>
          </div>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제3조 (회사정보 등의 제공)</p>
          <div>
            회사는 다음 각 호의 사항을 서비스 초기화면이나 서비스 홈페이지에
            게시하여 회원이 이를 쉽게 알 수 있도록 다만, 약관과 개인정보
            취급방침은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            <ol className="py-2">
              <li>상호 및 대표자성명</li>
              <li>사업장 소재지 주소 및 전자우편 주소</li>
              <li>전화번호, 팩스번호</li>
              <li>사업자등록번호</li>
              <li>통신판매업신고번호</li>
              <li>서비스이용약관</li>
              <li>개인정보취급방침</li>
            </ol>
          </div>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제4조 (약관의 게시와 개정)</p>
          <ol className="py-2">
            <li>
              회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
              화면이나 서비스 홈페이지에 게시합니다.
            </li>
            <li>
              회사는 서비스를 이용하고자 하는 자(이하 “이용자”라 한다)가 약관의
              내용을 알 수 있도록 작성하고 약관에 동의하기에 앞서 약관에
              정하여져 있는 내용 중 계약 해제ㆍ해지, 회사의 면책사항 및 이용자에
              대한 피해보상 등 중요한 내용을 이용자가 알 수 있도록 합니다.
            </li>
            <li>
              회사는「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의
              규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한
              법률」,「콘텐츠산업진흥법」등 관련 법령에 위배하지 않는 범위에서
              본 약관을 개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을
              명시하여 그 적용일자로부터 최소한 7일 이전(단, 이용자에게
              불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후
              상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을
              통해 기존회원에게는 알림 공지 외에 개정약관을 전자우편주소로
              발송합니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에
              대한 회원의 동의 여부를 개정약관 공지 시 회원이 동의 또는 거부의
              의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께
              공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지
              않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.
            </li>
            <li>
              회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의
              내용을 적용하지 않으며, 이 경우 회원은 이용계약을 해지할 수 다만,
              기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가
              동의하지 않은 회원과의 이용계약을 해지할 수 있습니다.
            </li>
            <li>
              회사는 회사가 제공하는 개별 서비스에 대해서 별도의 이용약관(이하
              “개별약관”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는
              개별약관이 우선하여 적용됩니다.
            </li>
            <li>
              본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는
              개별약관 및「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의
              규제에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한
              법률」,「콘텐츠산업진흥법」등 관련 법령 또는 상관례에 따릅니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제5조 (운영정책)</p>
          <ol className="py-2">
            <li>
              약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 서비스
              내 질서를 유지하기 위하여 회사는 약관에서 구체적 범위를 정하여
              위임한 사항을 서비스 운영정책 (이하 “운영정책”이라 합니다)으로
              정할 수 있습니다.
            </li>
            <li>
              회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 초기 화면이나
              플랫폼에 게시하거나 연결화면을 제공하는 방법으로 회원에게
              공지하여야 합니다.
            </li>
            <li>
              회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을
              변경하는 것과 동일한 효력이 발생하는 운영정책 개정의 경우에는
              제4조의 절차에 따릅니다.
              <br />
              단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는
              제2항의 방법으로 사전에 공지합니다.
              <ol className="sub_list">
                <li>
                  약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
                </li>
                <li>회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>
                <li>
                  운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고
                  회원이 예측가능한 범위 내에서 운영정책을 개정하는 경우
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div className="col-12 mt-2">
          <h6 className="font-weight-bold">
            제2장 플랫폼 서비스 이용계약의 체결
          </h6>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제6조 (회원 로그인 아이디 및 비밀번호)</p>
          <ol className="py-2">
            <li>
              회사는 회원에 대하여 회원정보 보호, 서비스 이용안내 등의 편의를
              위해 회원이 선정하거나 회사가 임의로 일정한 문자 또는 숫자와의
              조합으로 관리자용 아이디와 비밀번호를 부여합니다.
            </li>
            <li>
              회사는 로그인정보를 통하여 당해 회원의 서비스 이용가능 여부 등의
              제반 업무를 수행합니다.
            </li>
            <li>
              회원은 자신의 로그인정보를 선량한 관리자로서의 주의 의무를 다하여
              관리하여야 회원이 본인의 로그인정보를 소홀히 관리하거나 제3자에게
              이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이
              있습니다.
            </li>
            <li>
              비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는
              보안상의 이유 등으로 언제든지 변경이 가능합니다.
            </li>
            <li>회원은 정기적으로 비밀번호를 변경하여야 합니다.</li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제7조 (회원 정보의 제공 및 변경)</p>
          <ol className="py-2">
            <li>
              회원은 본 약관에 의하여 회사에 정보를 제공하여야 하는 경우에
              진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한
              불이익에 대해서는 보호받지 못합니다.
            </li>
            <li>
              회원은 회원정보관리화면을 통하여 언제든지 자신의 개인정보를
              열람하고 수정할 수 다만, 서비스관리를 위해 필요한 관리자용
              아이디는 수정이 불가능합니다.
            </li>
            <li>
              회원이 기존의 데이터를 유지, 승계하면서 사업자등록번호가 변경되는
              경우에는 기업의 인수합병, 개인사업자의 법인전환등 회사에서
              인정하는 특정한 경우에 가능하며, 이 경우 반드시 회사에 요청하여
              조치 받으시기 바랍니다.
            </li>
            <li>
              회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로
              수정을 하거나 기타 방법으로 회사에 대하여 그 변경사항을 알려야
              합니다.
            </li>
            <li>
              회원이 아이디 및 비밀번호를 분실하였을 때 서비스를 이용하기
              위해서는 회원사 관리자를 통하여 확인하거나, 신규아이디를
              부여받으시고, 관리자 아이디를 제외한 SUB아이디에 대하여 회사는
              관리하지 않습니다.
            </li>
            <li>
              제3항과 제4항의 변경사항을 회사에 알리지 않아 발생한 불이익에
              대하여 회사는 책임을 지지 않습니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제8조 (유료 서비스 이용 계약의 성립)</p>
          <ol className="py-2">
            <li>
              회원가입후 즉시 이용이 가능한 서비스는 별도의 유료서비스
              가입안내가 없는 한 무료로 사용이 다만, 회사가 별도로 제공하는
              유료서비스가 있을 수 있으며, 유료 서비스를 이용하고자 하는 회원은
              회사가 제시하는 절차 또는 이와 유사한 절차에 의하여 이용 신청을 할
              수 회사는 회원의 이용 신청 승낙을 원칙으로 하며, 회원의 정확한
              거래 내용 파악을 위해 다음과 같은 정보를 제공 또는 명기합니다.
              <ol className="sub_list">
                <li>유료 서비스의 명칭 및 종류, 내용</li>
                <li>유료 서비스의 이용 방법, 이용료, 제한 등</li>
                <li>결제 방법의 선택 및 결제 정보의 입력</li>
                <li>
                  유료 서비스의 이용 신청에 관한 확인 또는 회사의 확인에 대한
                  동의
                </li>
              </ol>
            </li>
            <li>
              유료 서비스 이용 계약의 성립 시기는 ‘가입 완료’ 등 계약 이용 신청
              절차상에서 동의 의사를 표시한 시점을 기준으로 합니다.
            </li>
            <li>
              회사는 아래 사항에 해당하는 경우에 대해서 승낙을 보류할 수
              있습니다.
              <ol className="sub_list">
                <li>
                  실제 사업자명이 아니거나, 다른 사업자의 명의 도용 등 회원 등록
                  시 허위로 신청하는 경우
                </li>
                <li>
                  이용자 등록 사항을 누락하거나 오기하여 이용 신청 요건이
                  만족되지 않은 경우
                </li>
                <li> 부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                <li>
                  법령 또는 약관을 위반하여 이용계약이 해지된 적이 있는 이용자가
                  신청하는 경우
                </li>
                <li>기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
              </ol>
            </li>
            <li>
              회사는 서비스 이용 신청이 다음 각 호에 해당하는 경우에는 그 신청에
              대하여 승낙 제한 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
              <ol className="sub_list">
                <li>회사가 설비의 여유가 없는 경우</li>
                <li>회사의 기술 상 지장이 있는 경우</li>
                <li>기타 회사의 귀책사유로 이용 승낙이 곤란한 경우</li>
              </ol>
            </li>
            <li>
              회사는 회원 가입 절차 완료 이후 본조 제3항 각 호에 따른 사유가
              발견된 경우 이용 승낙을 사후에 철회할 수 있습니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제9조 (유료 서비스 이용)</p>
          <ol className="py-2">
            <li>
              회사의 유료 서비스에 대한 대금지급방법은 다음 각호의 방법 중
              가용한 방법으로 할 수 있습니다.
              <ol className="sub_list">
                <li>인터넷뱅킹 등의 각종 계좌이체</li>
                <li>직불카드, 신용카드 등의 각종 신용카드</li>
                <li>온라인 무통장입금</li>
                <li>기타 전자적 지급방법에 의한 대금지급 등</li>
              </ol>
            </li>
            <li>
              기타 유료회원의 이용료와 결재일등 구체적은 사항은 별도의 계약에
              따릅니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제10조 (개인정보의 보호 및 관리)</p>
          <ol className="py-2">
            <li>
              회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의
              개인정보를 보호하기 위해 노력하며, 회원 개인정보의 보호 및 사용에
              대해서는 관계법령 및 회사가 별도로 고지하는 개인정보취급방침이
              적용됩니다.
            </li>
            <li>
              서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및
              서비스별 웹사이트에서 단순히 링크된 제3자 제공의 서비스에 대하여는
              회사의 개인정보취급방침이 적용되지 않습니다.
            </li>
            <li>
              회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한
              모든 정보에 대해서는 일체의 책임을 지지 않습니다.
            </li>
          </ol>
        </div>

        <div className="col-12 mt-2">
          <h6 className="font-weight-bold">제3장 계약당사자의 의무</h6>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제11조 (회사의 의무)</p>
          <ol className="py-2">
            <li>
              회사는 관련 법령을 준수하고 본 약관이 정하는 권리의 행사와 의무를
              성실하게 이행하며, 지속적이고 안정적인 플랫폼 서비스를 제공하는데
              최선을 다합니다.
            </li>
            <li>
              회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를
              위해 보안시스템을 갖추어야 하며, 개인정보취급방침을 공시하고 또한
              본 약관 및 개인정보취급방침에서 정한 경우를 제외하고는 회원의
              개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
            </li>
            <li>
              회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지
              않습니다.
            </li>
            <li>
              회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을
              하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변,
              비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한
              사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을
              다합니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제12조 (회원의 의무)</p>
          <ol className="py-2">
            <li>
              회원은 다음 행위를 하여서는 안 됩니다.
              <ol className="sub_list">
                <li>신청 또는 변경 시 허위내용의 기재</li>
                <li>타인의 정보 도용</li>
                <li>회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위</li>
                <li>회사가 게시한 정보의 변경</li>
                <li>회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
                <li>
                  회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는
                  장치를 제작, 배포, 이용, 광고하는 행위
                </li>
                <li>회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                <li>
                  회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                </li>
                <li>
                  외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 사회질서에
                  반하는 정보를 회사의 플랫폼에 공개 또는 게시하는 행위
                </li>
                <li>
                  서비스 데이터를 유상으로 처분하거나 권리의 객체로 하는 행위
                </li>
                <li> 제10호의 행위를 유도하거나 광고하는 행위</li>
                <li>
                  회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로
                  서비스를 사용하는 행위
                </li>
                <li>
                  기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상
                  허용되지 않는 행위.
                </li>
              </ol>
            </li>
            <li>
              회원은 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
              주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가
              있습니다.
            </li>
            <li>
              회사는 제1항, 제3항 및 다음 각 호의 어느 하나에 해당하는 행위의
              구체적인 유형을 운영정책에서 정할 수 있으며, 회원은 이를 준수할
              의무가 있습니다.
              <ol className="sub_list">
                <li>
                  회원의 로그인 이메일명, 기타 서비스상 사용하는 명칭에 대한
                  제한
                </li>
                <li>댓글이용에 대한 제한</li>
                <li>서비스 이용방법에 대한 제한</li>
                <li>
                  기타 회원의 서비스 이용에 대한 본질적 권리를 침해하지 않는
                  범위 내에서 회사가 서비스 운영상 필요하다고 인정되는 사항
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div className="col-12 mt-2">
          <h6 className="font-weight-bold">제4장 서비스 이용</h6>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제13조 (서비스의 변경 및 내용수정)</p>
          <ol className="py-2">
            <li>
              회원은 회사가 제공하는 서비스를 본 약관, 운영정책 및 회사가 설정한
              서비스규칙에 따라 이용할 수 있습니다.
            </li>
            <li>
              회사는 서비스 내용의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을
              가집니다.
            </li>
            <li>
              회사가 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
              제공하고 있는 서비스를 수정할 수 있으며, 서비스 수정을 하는
              경우에는 변경 후 해당 서비스 화면 등을 통하여 공지합니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제14조 (서비스의 제공 및 중단 등)</p>
          <ol className="py-2">
            <li>
              회사는 영업방침에 따라 정해진 시간동안 서비스를 제공하며, 서비스를
              일정 범위로 분할하여 각 범위별로 이용가능 시간을 별도로 지정할 수
              있습니다.
            </li>
            <li>
              회사는 다음 각 호에 해당하는 경우 일정한 시간동안 서비스의 제공을
              중단할 수 있습니다.
              <ol className="sub_list">
                <li>
                  컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는
                  서비스의 수정을 위하여 필요한 경우
                </li>
                <li>
                  해킹, 통신사고, 회원들의 비정상적인 서비스 이용, 미처 예상하지
                  못한 서비스의 불안정성에 대응하기 위하여 필요한 경우
                </li>
                <li>
                  관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는
                  경우
                </li>
                <li>
                  천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스
                  이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우
                </li>
                <li>
                  회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익
                  악화 등 회사의 경영상 중대한 필요에 의한 경우
                </li>
              </ol>
            </li>
            <li>
              회사는 제2항 제1호의 경우, 매주 또는 격주 단위로 일정 시간을
              정하여 서비스를 중지할 수 있습니다. 이 경우 회사는 최소한 24시간
              전에 그 사실을 회원에게 서비스 초기 화면이나 서비스 플랫폼에
              고지합니다.
            </li>
            <li>
              제2항 제2호의 경우, 회사는 사전 고지 없이 서비스를 일시 중지할 수
              있습니다. 회사는 이러한 경우 그 사실을 서비스 초기 화면이나 서비스
              플랫폼에 사후 고지할 수 있습니다.
            </li>
            <li>
              회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게
              발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의
              고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
            </li>
            <li>
              제2항 제3호 및 제5호의 경우에 회사는 기술상 · 운영상 필요에 의해
              서비스 전부를 중단할 수 있으며, 이를 사전에 공지합니다. 사전에
              공지할 수 없는 부득이한 사정이 있는 경우는 사후에 공지를 할 수
              있습니다.
            </li>
            <li>
              회사가 부득이한 사유에 따라 서비스를 종료하는 경우 회원은 서비스
              이용이 중단됨에 따른 손해배상을 청구할 수 없습니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제15조 (저작권 및 지적재산권 등 권리의 귀속)</p>
          <ol className="py-2">
            <li>
              회사가 제공하는 서비스 내 모든 콘텐츠에 대한 저작권 및 기타
              지적재산권은 회사에 귀속됩니다.
            </li>
            <li>
              회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사
              또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의
              사전승낙 없이 복제, 송신, 출판, 배포, 방송 등의 방법에 의하여
              영리목적으로 이용하거나 제3자에게 제공하여서는 안됩니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제16조 (서비스 이용계약의 해지 및 이용 제한)</p>
          <ol className="py-2">
            <li>
              회사는 회원이 제10조에서 정하는 회원의 의무를 위반한 경우 회원에게
              사전 통보 후 서비스 이용계약을 해지할 수 있습니다. 단, 시정이
              가능하다고 인정되는 경우 회원에게 그 사유를 통지하고 일정한
              기간동안 회원에게 경고, 일시정지, 영구이용정지 등으로 서비스의
              이용을 단계적으로 제한할 수 있습니다.
            </li>
            <li>
              회사가 계약을 해지하는 경우 회사는 회원에게 서면 또는 전자우편의
              방법으로 해지사유를 통보합니다.
            </li>
            <li>
              제1항의 사유로 계약이 해지되었을 경우 회원은 서비스의 사용권한 및
              서비스 이용을 통해 획득한 혜택 등을 상실하며, 이로 인한 손해배상을
              청구할 수 없습니다. 다만, 회원이 작성하여 카드로에 보관중인
              결제요청서 및 파일등은 요청에 의하여 제공받을 수 있습니다
            </li>
            <li>
              회원은 계약 해지 및 이용제한에 대하여 이의신청을 할 수 있으며,
              이의신청이 정당하다고 회사가 인정하는 경우 회사는 서비스의 이용을
              재개합니다.
            </li>
          </ol>
        </div>

        <div className="col-12 mt-2">
          <h6 className="font-weight-bold">제5장 손해배상 등</h6>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제17조 (손해배상)</p>
          <ol className="py-2">
            <li>
              회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여
              배상할 책임이 있습니다.
            </li>
            <li>
              회원이 본 약관을 위반하거나 위법한 행위로 회사에 손해를 끼친 경우,
              회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제18조 (회사의 면책)</p>
          <ol className="py-2">
            <li>
              회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검
              또는 교체, 고장, 통신두절 등으로 인하여 원활한 서비스를 제공할 수
              없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
            </li>
            <li>
              회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애 및
              계약해지에 대하여는 책임이 면제됩니다.
            </li>
            <li>
              회사는 회원이 작성한 게시물에 대하여 신뢰도, 정확성 등을 보증하지
              않으며, 이를 통하여 서비스제공자가 제공한 잘 못 된 서비스에로 인한
              손해에 대하여는 책임을 지지 않습니다.
            </li>
            <li>
              회사는 회원 상호간 또는 제3자 상호간에 서비스를 매개로 발생한
              분쟁, 개별적인 거래와 관련하여 책임을 지지 않습니다.
            </li>
            <li>
              회사는 무료로 제공하는 서비스와 관련하여 회사의 고의 또는 중대한
              과실이 없는 한 책임을 지지 않습니다.
            </li>
            <li>
              회사 서비스 중 일부 서비스는 다른 사업자가 제공하는 서비스를
              통하여 제공될 수 있으며, 회사는 다른 사업자가 제공하는 서비스로
              인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한 과실이
              없는 한 책임을 지지 않습니다.
            </li>
            <li>
              회사는 관련 법령, 정부 정책 등에 의하여 서비스 또는 회원에 따라
              서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에
              따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이
              면제됩니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제19조 (회원에 대한 통지)</p>
          <ol className="py-2">
            <li>
              회사가 회원에게 통지를 하는 경우 회원이 지정한 전자우편, 휴대폰
              SMS, 전자쪽지 등으로 할 수 있습니다. 다만, 회원이 변경된
              회원정보를 수정하지 않아 통지를 받지 못한 경우 회원은 통지받지
              못한 것을 이유로 회사에 책임을 물을 수 없습니다.
            </li>
            <li>
              회사는 회원 전체에게 통지를 하는 경우 서비스 초기화면에 게시하거나
              팝업화면, 알림메세지 등을 제시함으로써 제1항의 통지에 갈음할 수
              있습니다.
            </li>
          </ol>
        </div>
        <div className="col-12 py-4">
          <p className="pb-2">제20조 (준거법 및 재판권)</p>
          <ol className="py-2">
            <li>
              회사와 회원간에 발생한 분쟁에 관한 소송은 대한민국법을 준거법으로
              합니다.
            </li>
            <li>
              회사와 회원간에 발생한 분쟁에 관한 소송의 관할은 민사소송법이
              정하는 바에 따릅니다.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
