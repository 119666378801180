import React from "react";

export default function GBTSLogoSvg({
                                      point = "#3b44ac",
    color = "#38383a",
  width,
  height = "30px",
}) {
  return (
    <svg
      id="GBTS_LOGO"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 71.1"
      height={height}
    >
      <path
        d="M277.88,26.06H242.77s-6.54-.25-6.54-6.53,6.2-6.21,6.2-6.21h50.28V0H243.27s-20.2.25-20.2,18.35S242.26,39.3,242.26,39.3H277s9.72,1.17,9.72,9.72-8.47,9.85-8.47,9.85H92.36V41.23H126.2c9.33,3.11,6.82,13.28,6.11,15.11H146V52c0-12.07-7.21-18.19-7.21-18.19,5.28-3.93,5-13.74,5-13.74C144.22,0,123.52,0,123.52,0H41.73S0-.08,0,34.78,37.21,71.1,37.21,71.1H277.92S300,70.39,300,49,277.88,26.06,277.88,26.06ZM124.11,12c7.2,1.6,7.29,8.8,7.29,8.8,0,6.37-7,8-7,8h-32V12ZM75.75,58.87V29H37.29V41.31H62.43V58.87H40.52a32.32,32.32,0,0,1-16.11-4.1C18.7,51.5,13,45.8,13,36a22.44,22.44,0,0,1,8-17.66A27.4,27.4,0,0,1,38.91,12H80.13V58.87Z"
        fill={color}
      />
      <path
        d="M192.49,13H219.8c2-11.4,10-12.82,10-12.82H138.35a21.73,21.73,0,0,1,9.47,12.9h30.25v41.1h14.42Z"
        fill={point}
      />
    </svg>
  );
}
